import React, { useEffect, useState } from 'react';
import { environmentVariableList } from '../../../config/env';
import { Button, Icon, Modal, Spin } from 'antd';
import Cafe from "../../../assets/images/CareersImgs/Cafe.png";
import ReactPlayer from "react-player";
import { css } from '@emotion/core';


export const DynamicBanner = () => {
  const [modalShown, setmodalShown] = useState(false);
  const closeModal = () => {
    setBanner(false)
  };
  const [loading, setloading] = useState<boolean>(false)
  const [banner, setBanner] = useState<boolean>(false)
  const [bannerData, setBannerData] = useState<any>(null)
  const [modelData, setModelData] = useState<any>(null)
  const [playing, setPlaying] = useState(true);
  const toggle = () => setmodalShown(!modalShown);

  const isBannerVisible = async () => {
    setloading(true)
    try {
      const response = await fetch(
        `${environmentVariableList.REACT_APP_DEALER_DETAIL}/api/Banners/GetBanner`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      // console.log(response);
      const data = await response.json();
      if (data.Success === true) {
        const Filterdata: any = data.BannerList.filter((item: any) => item.Portal === "RumbleOn" && item.Type === "Banner")
        if (Filterdata.length > 0) {
          const res = Filterdata[0];
          setBannerData(res);
          setBanner(true)
        }
        const FilterdataModel: any = data.BannerList.filter((item: any) =>
          item.Portal === "RumbleOn" && item.Type === "Modal")
        if (FilterdataModel.length > 0) {
          const res = FilterdataModel[0];
          setModelData(res);
          setmodalShown(true)
        }
      } else {
        setBanner(false)
        setmodalShown(false)
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setloading(false)
    }
  }
  useEffect(() => {
    isBannerVisible();
  }, []);
  console.log("BannerData::", bannerData)
  return (<>
    {banner &&
      <div
        css={css` width: 100%;
              height: 100%;
              `}
      >
        <div css={css`
                  padding: 0 1%;
                  height: fit-content !important;
                  display: grid;
                  grid-template-columns: 95% 5%;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  z-index: 99999999;
                  // position: absolute;
                  // top: 5rem;
                `}
          style={{
            backgroundColor: `${bannerData?.InfoType?.toLowerCase().replace(/\s/g, '')
              === "info"
              ? '#76b2d5' :
              bannerData?.InfoType?.toLowerCase().replace(/\s/g, '')
                === "error" ? '#fff1f0' : '#92dab0'
              }`

          }}>
          <div css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                  `}>
            <div css={css`
           display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;`}>
              <Icon type="exclamation-circle" />
              <p css={css` font-size: 1.1em;
  margin: 0 !important;`}>
                Attention
              </p>
            </div>
            <p css={css`
           font-size: 1.3em;
  margin: 0 !important;
  color: #1f1a1a;
          `}>
              {bannerData.DisplayText}
            </p>
          </div>

          <div css={css`
        display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 3%;
        `}>
            <Button type="primary" shape="circle" icon="close-circle" size="small" onClick={() => closeModal()} />
          </div>

        </div>
      </div>}
    {modalShown &&
      <Modal
        visible={modalShown}
        centered
        maskClosable={false}
        footer={null}
        destroyOnClose={true}
        onCancel={() => toggle()}
        title={"Great News"}
        css={css` 
         width:600px !important;
         
        `}>

        {loading ? (
          <div css={css`
          display:flex;
          justify-content:center;
          align-items:center;
          height:200px;
          `}>
            <Spin spinning={loading} />
          </div>
        ) : (
          <>
            {modelData.ImageUrl !== "" &&
              modelData.ImageUrl !== undefined &&
              modelData.ImageUrl !== null &&
              <img
                css={css`
                width:100%;
                object-fit:fill;
                height:auto;
                max-height:350px;`}
                alt="image"
                src={modelData.ImageUrl} />}
            {modelData.ImageUrl === null &&
              modelData.VideoUrl !== "" &&
              modelData.VideoUrl !== undefined &&
              modelData.VideoUrl !== null &&
              <ReactPlayer
                // src={""}
                url={modelData.VideoUrl}
                autoPlay={true}
                playing={playing}
                controls={true}
                css={css`
                width:100% !important;
                height:auto !important;
                
                `}
              />}
          </>)}
      </Modal>
    }
  </>

  );
};