/* eslint-disable */

import * as React from "react";

import { VehicleDetailAttachments } from "../generated/graphql";

type State = {
  listingId?: number | string;
  vin?: string;
  listingYear?: number;
  make?: string;
  model?: string;
  trim?: string | null;
  itemTypeId?: number;
  interiorColor?: string | null;
  zip?: string | null;
  mileage?: number | null;
  price?: number | null;
  attachments?: Array<VehicleDetailAttachments>;
  thumbnailAttachmentId?: number;
};

type Action =
  | { type: "ADD_DETAILS"; args: State }
  | { type: "SET_THUMB_ATTACHMENT"; args: State }
  | { type: "RESET_DETAILS" };

type Dispatch = (action: Action) => void;
type DetailsFlowContextProps = {
  children: React.ReactNode;
};

const DetailsFlowStateContext = React.createContext<State | undefined>(
  undefined
);

const DetailsFlowDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);
const localStorageKey = "DETAILS_FLOW_LOCAl_STORAGE_KEY";

let localDetailsFlowState = {};
try {
  localDetailsFlowState = JSON.parse(
    localStorage.getItem(localStorageKey) || "{}"
  );
} catch (error) {
  console.error("Parse local state failed");
}
const DetailsFlowContextReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "ADD_DETAILS":
      return {
        ...state,
        ...action.args,
      };
    case "SET_THUMB_ATTACHMENT":
      return {
        ...state,
        ...action.args,
      };
    case "RESET_DETAILS":
      return {};
    default:
      return state;
  }
};

const DetailsFlowContextProvider = ({ children }: DetailsFlowContextProps) => {
  const [state, dispatch] = React.useReducer(
    DetailsFlowContextReducer,
    localDetailsFlowState
  );
  React.useEffect(() => {
    // save  flow info to local storage
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state]);
  return (
    <DetailsFlowStateContext.Provider value={state}>
      <DetailsFlowDispatchContext.Provider value={dispatch}>
        {children}
      </DetailsFlowDispatchContext.Provider>
    </DetailsFlowStateContext.Provider>
  );
};

const useDetailsFlowState = () => {
  const context = React.useContext(DetailsFlowStateContext);
  if (context === undefined) {
    throw new Error(
      "useSellFlowState must be used within a SellFlowContextProvider"
    );
  }
  return context;
};

const useDetailsFlowDispatch = () => {
  const context = React.useContext(DetailsFlowDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSellFlowDispatch must be used within a SellFlowContextProvider"
    );
  }
  return context;
};

export {
  DetailsFlowContextProvider,
  useDetailsFlowState,
  useDetailsFlowDispatch,
};
