import MaintenancePageBg from "../../assets/images/maintenance_page_2x.png";
import MaintenancePageMobileBg from "../../assets/images/maintenance_page_mobile.png";
import React from "react";
import { css } from "@emotion/core";

const MaintenancePage: React.FC = () => {
  return (
    <div
      css={css`
        display: flex;
        background-color: #ffffff;
        flex-direction: column;
        background: url(${MaintenancePageBg});
        // background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 600px;
        // border: 2px solid black;
        box-shadow: 0px 0px 1px;
        align-items: center;
        text-align: center;
        padding: 20px;
        // border-radius: 5px;
        justify-content: center;
        font-size: 13px;
        // margin-top: 1rem;
        @media (max-width: 768px) {
          background: url(${MaintenancePageMobileBg});
          background-repeat: no-repeat;
          background-size: cover;
        }
      `}
    >
      {/* <Row
        css={css`
          width: 900px;
          text-align: justify;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <Col xs={24} lg={12}>
          <div>
            <h3>COMING SOON</h3>
            <h3>LIVE AUCTION ON RUMBLEON.COM!</h3>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div>asdasdas</div>
        </Col>
      </Row> */}
    </div>
  );
};

export default MaintenancePage;
