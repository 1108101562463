/* eslint-disable */

import * as React from "react";

import {
  VehicleAttachmentInput,
  VehicleOptionInput,
} from "../generated/graphql";

type State = {
  listingId?: number | string;
  Vin?: string;
  listingYear?: number;
  category?: string;
  make?: string;
  model?: string;
  inputMake?: string;
  inputModel?: string;
  isother?: boolean;
  trim?: string | null;
  itemTypeId?: number;
  isMileageUnknown?: boolean;
  zip?: string | null;
  mileage?: number | null;
  primaryColorId?: number | null;
  secondaryColorId?: number | null;
  engineSize?: string | null;
  engineType?: string | null;
  bodyStyle?: string | null;
  driveTrain?: string | null;
  transmission?: string | null;
  transmissionTypeId?: number | null;
  titledInState?: string | null;
  titleStatusId?: number | null;
  hasABS?: boolean | null;
  hasBeenSmokedIn?: boolean | null;
  hasWarranty?: boolean | null;
  hasDamage?: boolean | null;
  damageDescription?: string | null;
  hasBeenOperated?: boolean | null;
  overallConditionId?: number | null;
  physicalConditionId?: number | null;
  mechanicalConditionId?: number | null;
  tireConditionId?: number | null;
  price?: number | null;
  categoryId?: number | null;
  listingDescription?: string | null;
  listingOptions?: string | null;
  listingAbout?: string | null;
  priceMSRP?: number | null;
  pricePPMV?: number | null;
  priceNADA?: number | null;
  priceFMV?: number | null;
  priceRanking?: number | null;
  hasOutstandingLoan?: boolean | null;
  sourceTypeId?: number | null;
  loanBank?: string | null;
  loanAmount?: number | null;
  willSwap?: boolean | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  bookDate?: string | null;
  bookData?: string | null;
  manualVINEntry?: boolean | null;
  // manualInputVINEntry?: boolean | null;
  attachments?: VehicleAttachmentInput[] | null;
  options?: VehicleOptionInput[] | null;
  id?: string | null; // this is the trim id from vin decode
  decoder?: string | null; // kbb, nada etc from vindecode
  fuelType?: string | null;
  highwayMileage?: number | null;
  cityMileage?: number | null;
  isFromConsumerWeb?: boolean | null;
  currentSteps?: string | "1";
  runDescription?: string | null;
  doesYourCarRun?: boolean | null;
};

type Action =
  | { type: "CREATE_LISTING"; args: State }
  | { type: "UPDATE_LISTING"; args: State }
  | { type: "RESET_LISTING" };

type Dispatch = (action: Action) => void;
type SellFlowContextProviderProps = {
  children: React.ReactNode;
};

const SellFlowStateContext = React.createContext<State | undefined>(undefined);

const SellFlowDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);
const localStorageKey = "SELL_FLOW_LOCAL_STORAGE_KEY";

let localSellFlowState = {};
try {
  localSellFlowState = JSON.parse(
    localStorage.getItem(localStorageKey) || "{}"
  );
} catch (error) {
  console.error("Parse local state failed");
}
const SellFlowContextReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "CREATE_LISTING":
      return {
        ...state,
        ...action.args,
      };
    case "UPDATE_LISTING":
      return {
        ...state,
        ...action.args,
      };
    case "RESET_LISTING":
      return {};
    default:
      return state;
  }
};

const SellFlowContextProvider = ({
  children,
}: SellFlowContextProviderProps) => {
  const [state, dispatch] = React.useReducer(
    SellFlowContextReducer,
    localSellFlowState
  );
  React.useEffect(() => {
    // save sell flow info to local storage
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state]);
  return (
    <SellFlowStateContext.Provider value={state}>
      <SellFlowDispatchContext.Provider value={dispatch}>
        {children}
      </SellFlowDispatchContext.Provider>
    </SellFlowStateContext.Provider>
  );
};

const useSellFlowState = () => {
  const context = React.useContext(SellFlowStateContext);
  if (context === undefined) {
    throw new Error(
      "useSellFlowState must be used within a SellFlowContextProvider"
    );
  }
  return context;
};

const useSellFlowDispatch = () => {
  const context = React.useContext(SellFlowDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSellFlowDispatch must be used within a SellFlowContextProvider"
    );
  }
  return context;
};

export { SellFlowContextProvider, useSellFlowState, useSellFlowDispatch };
