import "./Layout.css";

import * as React from "react";

import { Alert, Col, Icon, Layout, Menu, Row } from "antd";

import CookieConsent from "./components/CookieeConsent";
import { DynamicBanner } from "./components/Banner";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MainMenu from "./components/MainMenu";
import MobileTopLeftMenu from "./components/MobileTopLeftMenu";
import TopRightMenu from "./components/TopRightMenu";
import { breakpoints } from "../../constants";
import { css } from "@emotion/core";
import { environmentVariableList } from "../../config/env";
import queryString from "query-string";

const { Header, Content } = Layout;
const { SubMenu } = Menu;
interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const dealerBranded = queryString.parseUrl(window.location.search).query
    ?.utm_source;

  return (
    <Layout
      css={css`
        width: 100%;
        max-width: 1800px;
        margin: 0px auto;
        background: #fafafa;
      `}
    >
      {dealerBranded !== "jdpower" &&
        dealerBranded !== "lawtiger" &&
        dealerBranded !== "cycletrader" && (
          <>
            <Header
              //     css={css`
              //       height: 2.8em;
              //       background: #454545;
              //       display: flex;
              //       justify-content: flex-end;
              //       z-index: 1;
              //       padding: 0 0 0 0 !important;
              //       /* Hide in small breakpoint */
              //       @media (max-width: 1103px) {
              //         display: none;
              //       }
              //     `}
              // >
              //     <Menu
              //       mode="horizontal"
              //       css={css`
              //         font-weight: bold;
              //         line-height: 2.5em;
              //         background: #454545;
              //         color: white !important;
              //         border: none;
              //         flex: display;
              //         font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              //         font-weight: 300 !important;
              //         font-size: 16px !important;
              //         justify-content: space-between;
              //         .ant-menu-item-selected {
              //           color: white !important;
              //         }
              //       `}
              //     >

              /*{ <Menu.Item>
            <Link to="/dealerships">Find a Dealer </Link>
          </Menu.Item> }*/
              /*{ <Menu.Item key="1">
                  <a
                    css={css`
                      color: white !important;
                      font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                      font-weight: 300 !important;
                      font-size: 16px !important;
                    `}
                    href={environmentVariableList.DEALER_PORTAL_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dealer Portal
                  </a>
                </Menu.Item>
                <SubMenu
                  css={css`
                    color: white !important;
                    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                    font-weight: 300 !important;
                    font-size: 16px !important;
                    .ant-menu-item-selected {
                      color: white !important;
                    }
                  `}
                  key="sub1"
                  title={
                    <div
                      css={css`
                        color: white !important;
                        font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                        font-weight: 300 !important;
                        font-size: 16px !important;
                      `}
                    >
                      About
                      <Icon
                        css={css`
                          margin-left: 5px;
                        `}
                        type="caret-down"
                        theme="filled"
                      />
                    </div>
                  }
                > {*/
              /*{ <Menu.Item key="19">
              <a
                href="https://help.rumbleon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </Menu.Item> }*/
              /*{ <Menu.Item key="9">
              <Link to="/list-your-vehicle/step-1">Selling a Motorcycle</Link>
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/list-your-vehicle/step-1">Selling an ATV</Link>
            </Menu.Item> */
              /* <Menu.Item key="11">
                    <Link to="/about-us">About Us</Link>
                  </Menu.Item> */
              /* <Menu.Item key="12">
              <a
                href="https://go.rumbleon.com/about-rumbleon-finance"
                target="_blank"
                rel="noopener noreferrer"
              >
                About RumbleOn Finance
              </a>
            </Menu.Item> */
              /* <Menu.Item key="13">
              <a
                href="https://www.aboutmyloan.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                RumbleOn Loan Payment
              </a>
            </Menu.Item> */
              /* <Menu.Item key="14">
              <a
                href="https://www.rumbleswag.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get RumbleOn Swag
              </a>
            </Menu.Item> */
              /* <Menu.Item key="15">
                    <Link to="/contact-us">Contact Us</Link>
                  </Menu.Item>
                  <Menu.Item key="faq">
                    <Link to="/faq">FAQ</Link>
                  </Menu.Item>
                  <Menu.Item key="16">
                    <a
                      href="https://go.rumbleon.com/kickstart-your-career"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Careers
                    </a>
                  </Menu.Item>
                  <Menu.Item key="17">
                    <a
                      href="https://investors.rumbleon.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Investors
                    </a>
                  </Menu.Item>
                  <Menu.Item key="18">
                    <a
                      href="https://explore.rumbleon.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </Menu.Item>
                  <Menu.Item key="19">
                    <a
                      href="https://go.rumbleon.com/rally-with-rumbleon-upcoming-events"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Events
                    </a>
                  </Menu.Item>
                </SubMenu>
              </Menu>
                </Header> */
              css={css`
                background: white;
                display: flex;
                z-index: 1;
                // border-bottom: 0.5px solid lightgray;
                box-shadow: 0px 1px 2px lightgray;
                padding: 5px !important;
                justify-content: space-between;
                align-items: center;
                @media (max-width: 1103px) {
                  display: none;
                }
              `}
            >
              {/* <div> */}
              <Link
                to="/"
                css={css`
                  overflow: hidden;
                  padding-left: 15px;
                `}
              >
                <img
                  alt="logo"
                  src={require("./images/rumbleon-black-logo.svg")}
                  css={css`
                    /* Mobile style */
                    background-repeat: no-repeat;
                    background-position: 100%;
                    height: 66px;
                    width: 219px;
                    margin: 0 12px;
                    float: left;
                  `}
                ></img>
              </Link>
              <Helmet>
                <script src="https://cdn.userway.org/widget.js" />
                <script id="google-analytics">
                  {` (function(d){
        var s = d.createElement("script");

        s.setAttribute("data-account", "qLb3sVM6fr");
        s.setAttribute("src", "https://cdn.userway.org/widget.js");
        (d.body || d.head).appendChild(s);
        })(document)`}
                </script>
              </Helmet>
              {/* </div> */}
              <MainMenu />
              <TopRightMenu />
            </Header>
            <CookieConsent />
            <Header
              css={css`
                background: white;
                z-index: 1;
                padding: 0;
                @media (min-width: 1104px) {
                  display: none;
                }
              `}
            >
              <Row
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 0 0 0 25px;
                `}
              >
                <Col
                  css={css`
                    width: 100%;
                  `}
                >
                  <Link to="/" css={css``}>
                    <img
                      alt="logo"
                      src={require("./images/rumbleon-black-logo.svg")}
                      css={css`
                        background-repeat: no-repeat;
                        background-position: 100%;
                        height: 66px;
                        width: 190px;
                      `}
                    />
                  </Link>
                </Col>
                <Col>
                  <MobileTopLeftMenu />
                </Col>
              </Row>
            </Header>
          </>
        )}

      {/* Uncomment if need a banner for messages */}
      {/* <Row>
        <Col span={24}>
          <Alert
            message={`We are currently undergoing some new upgrades and enhancements to our inventory pages. Check back soon to see our large selection of Dealer Inventory.

`}
          />
        </Col>
      </Row> */}

      <Content
        css={css`
          padding: 0;
          @media (max-width: ${breakpoints.lg}) {
            padding: 0;
          }
        `}
      >
        {" "}
        <DynamicBanner />
        {children}
      </Content>
      {/* <PageDivider /> */}
      {dealerBranded !== "jdpower" &&
        dealerBranded !== "lawtiger" &&
        dealerBranded !== "cycletrader" && <Footer />}
    </Layout>
  );
};

export default AppLayout;
