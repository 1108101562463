import { Alert, Button, Col, Form, Input, Row, notification } from "antd";

import { FormComponentProps } from "antd/lib/form";
import { Mutation } from "../../generated/graphql";
import React from "react";
import TextArea from "antd/lib/input/TextArea";
import { css } from "@emotion/core";
import { formatCurrency } from "../../utils/format";
import { loader } from "graphql.macro";
import { useAuthPopupState } from "../../context/AuthPopupContext";
import { useDetailsFlowState } from "../../context/DetailsFlowContext";
import { useModalFlowDispatch } from "../../context/ModalContext";
import { useMutation } from "react-apollo";

const SEND_BUY_LEAD = loader("../graphql/buyLead.graphql");

interface Props extends FormComponentProps {}

const RequestInfoForm: React.FC<Props> = ({ form }) => {
  const dispatch = useModalFlowDispatch();
  const state = useAuthPopupState();
  const detailState = useDetailsFlowState();
  const { Uuid, FirstName, LastName, Email, Mobile } = state;

  const {
    vin,
    make,
    model,
    listingYear,
    price,
    listingId,
    mileage,
  } = detailState;
  const MESSAGE = `I am interested in your ${listingYear} ${make} ${model} listed for ${formatCurrency(
    Number(price)
  )} for TradeIn.`;

  const [buyLeadMutationResult, { data: buyLeadResponse, error }] = useMutation<
    Mutation
  >(SEND_BUY_LEAD);

  const handleRequest = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields(async (error, values) => {
      console.log(values);
      if (!error) {
        const { firstName, lastName, email, phone, message } = values;
        try {
          const { data } = await buyLeadMutationResult({
            variables: {
              UserUuid: Uuid || null,
              FirstName: firstName,
              LastName: lastName,
              Email: email,
              Phone: phone,
              Message: message,
              ListingId: Number(listingId),
              LeadTypeId: 4,
              ActivityTypeId: 1,
              Vin: vin,
              Year: listingYear?.toString(),
              Make: make,
              Model: model,
              Mileage: mileage?.toString(),
            },
          });

          console.log(data);
          if (data?.sendLead?.success === true) {
            dispatch({
              type: "HIDE_REQUEST_INFO_MODAL",
              args: {
                showRequestInfoModal: false,
              },
            });

            notification.success({
              message: "We have sent your message to the Dealer",
              placement: "bottomLeft",
            });
          }
        } catch (error) {
          // notification.error({ message: "Could not send your message" });
        } finally {
          form.resetFields();
        }
      }
    });
  };

  return (
    <div
      style={{ height: "100%" }}
      css={css`
        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh !important;
        }
      `}
    >
      <Form onSubmit={handleRequest}>
        <Row>
          <Col xs={24} lg={12}>
            <img src={""} alt="" />
          </Col>
        </Row>
        <Row>
          <h3>Send a message about Trade-In on this vehicle</h3>
        </Row>
        <Row gutter={8}>
          <Col xs={24} lg={12}>
            <Form.Item label="First Name">
              {form.getFieldDecorator("firstName", {
                initialValue: FirstName,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  css={css`
                    border: 0;
                    border-bottom: 1px solid;
                    outline: none;
                    // height: 100%;
                    width: 100%;
                    background: #eeeeee 0% 0% no-repeat padding-box;
                    border-radius: 4px 4px 0px 0px;
                    opacity: 1;
                    padding-left: 1rem;
                    height: 48px !important;
                    input {
                      height: 48px !important;
                    }
                    @media (max-width: 786px) {
                      font-size: 14px;
                    }
                  `}
                  // size="large"
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Last Name">
              {form.getFieldDecorator("lastName", {
                initialValue: LastName,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  css={css`
                    border: 0;
                    border-bottom: 1px solid;
                    outline: none;
                    // height: 100%;
                    width: 100%;
                    background: #eeeeee 0% 0% no-repeat padding-box;
                    border-radius: 4px 4px 0px 0px;
                    opacity: 1;
                    padding-left: 1rem;
                    height: 48px !important;
                    input {
                      height: 48px !important;
                    }
                    @media (max-width: 786px) {
                      font-size: 14px;
                    }
                  `}
                  size="large"
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Email">
              {form.getFieldDecorator("email", {
                initialValue: Email,
                rules: [
                  {
                    type: "email",
                    required: false,
                  },
                ],
              })(
                <Input
                  css={css`
                    border: 0;
                    border-bottom: 1px solid;
                    outline: none;
                    // height: 100%;
                    width: 100%;
                    background: #eeeeee 0% 0% no-repeat padding-box;
                    border-radius: 4px 4px 0px 0px;
                    opacity: 1;
                    padding-left: 1rem;
                    height: 48px !important;
                    input {
                      height: 48px !important;
                    }
                    @media (max-width: 786px) {
                      font-size: 14px;
                    }
                  `}
                  size="large"
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label="Phone">
              {form.getFieldDecorator("phone", {
                initialValue: Mobile,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  css={css`
                    border: 0;
                    border-bottom: 1px solid;
                    outline: none;
                    // height: 100%;
                    width: 100%;
                    background: #eeeeee 0% 0% no-repeat padding-box;
                    border-radius: 4px 4px 0px 0px;
                    opacity: 1;
                    padding-left: 1rem;
                    height: 48px !important;
                    input {
                      height: 48px !important;
                    }
                    @media (max-width: 786px) {
                      font-size: 14px;
                    }
                  `}
                  size="large"
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label="Message">
              {form.getFieldDecorator("message", {
                initialValue: MESSAGE,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <TextArea
                  css={css`
                    height: 100px !important;
                    border: 0;
                    border-bottom: 1px solid;
                    outline: none;
                    // height: 100%;
                    width: 100%;
                    background: #eeeeee 0% 0% no-repeat padding-box;
                    border-radius: 4px 4px 0px 0px;
                    opacity: 1;
                    padding-left: 1rem;
                    @media (max-width: 768px) {
                    }
                  `}
                />
              )}
            </Form.Item>
          </Col>

          {error && (
            <Col
              xs={24}
              lg={24}
              css={css`
                margin-bottom: 4%;
              `}
            >
              <Alert
                type="error"
                showIcon
                message={"Could not send your message"}
              />
            </Col>
          )}

          <Col xs={24} lg={12}>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              css={css`
                height: 48px !important;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              Request Information
            </Button>
          </Col>
          <a
            css={css`
              text-decoration: underline;
              text-underline-position: under;
              @media (max-width: 767px) {
                margin-left: 0 !important;
              }
            `}
            href="/termsofuse?#119"
            target="_blank"
            onClick={() => {
              dispatch({
                type: "HIDE_REQUEST_INFO_MODAL",
                args: {
                  showRequestInfoModal: false,
                },
              });
            }}
          >
            Do not sell my personal info.
          </a>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(RequestInfoForm);
