export const colors = {
  bgGrey: "#fafafa",
  darkBlack: "#2b2b2b", //button
  darkCharcoal: "#2B2B2B", //h1
  lightCharcoal: "#4C4C4C", //h3
  iconGray: "#757575",
  textGrey: "#4c4c4c",
  blue: "#1B51CF", //icon
  lightGrey: "#0000001F",
  superLightGray: "#EEEEEE",
  shadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  greenShade: "#5C903F",
  lightGreen: "#85ce06",
  errorRed: "#99100C",
  purple: "#873BD4",
  orangeTint: "#DB7012",
};

export const breakpoints = [576, 768, 992, 1200, 1600];

export const minMedia = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const maxMedia = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
