import moment from "moment";

export const formatNumber = (value: number) =>
  new Intl.NumberFormat("en-US").format(value);

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(value);

export const formatDateTime = (value: string) => {
  const parsedDate = new Date(value);
  return parsedDate.toLocaleDateString("en-US");
};

export const formatPhoneNumber = (phone: string) => {
  //Filter only numbers from the input
  const removeCode =
    (phone.length > 10 && phone[0] === "+" && phone.slice(2)) || phone;
  let cleaned = ("" + removeCode).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const formatDate = (value: string) => {
  const parsedDate = new Date(value);
  const a = moment();
  const b = moment(parsedDate);
  const expiredDateH = b.diff(a, "hours");
  const expiredDateD = b.diff(a, "days");
  const expiredDateM = b.diff(a, "months");
  const expiredDateY = b.diff(a, "years");
  const expiredDate = b.diff(a, "hours");
  // return parsedDate.toLocaleDateString("en-US");
  return expiredDate < 0
    ? expiredDateH < -24
      ? expiredDateD < -30
        ? expiredDateM < -1 && expiredDateM < -12
          ? expiredDateY > -1
            ? `expired ${Math.abs(expiredDateY)} years ago`
            : `expired ${Math.abs(expiredDateY)} year ago`
          : expiredDateM === -1
          ? `expired ${Math.abs(expiredDateM)} month ago`
          : `expired ${Math.abs(expiredDateM)} months ago`
        : expiredDateD === -1
        ? `expired ${Math.abs(expiredDateD)} day ago`
        : `expired ${Math.abs(expiredDateD)} days ago`
      : expiredDateH === -1
      ? ` expired ${Math.abs(expiredDateH)} hour ago`
      : ` expired ${Math.abs(expiredDateH)} hours ago`
    : `expires ${moment(b).fromNow()}`;
};
// `${Math.abs(expiredDateD)} hours ago`
// `${Math.abs(expiredDateH)} hours ago`

export const statusFormatter = (value: number) => {
  switch (value) {
    case 1:
      return "Incomplete";
    case 2:
      return "In Review";
    case 3:
      return "Approved";
    case 4:
      return "Sold";
    case 5:
      return "Denied";
    case 6:
      return "Deleted";
    case 7:
      return "Removed";
    case 8:
      return "Accepted";
    case 9:
      return "Published";
    case 10:
      return "Expired";
    case 11:
      return "In Review";
    case 12:
      return "Edited";
    default:
      return "";
  }
};

export const tokenFormatter = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const formatMileage = (value: number) =>
  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export function shuffleArray<T>(array: Array<T>): Array<T> {
  const newArray = array.slice();
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
