/* eslint-disable */

import * as React from "react";

import { LoginResult, UpdateProfileResult } from "../generated/graphql";
import { ROLE_KEY, roles } from "../constants";

import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

export const UPDATE_SMS_PREF = "UPDATE_SMS_PREF";

type Action =
  | { type: "POST_LOGIN_SUCCESS"; args: any }
  | { type: "LOG_OUT" }
  | { type: "SOFT_LOGOUT" }
  | { type: "RESET_AUTH" }
  | { type: "SHOW_LOGIN" }
  | { type: "SHOW_LOGIN_AND_VERIFY_EMAIL"; args: string }
  | { type: "REMOVE_EMAIL_TO_VERIFY" }
  | { type: "SHOW_SIGNUP" }
  | { type: "HIDE_AUTH_POPUP" }
  | { type: "POST_UPDATE_SUCCESS"; args: any }
  | { type: typeof UPDATE_SMS_PREF; args: string };

type Dispatch = (action: Action) => void;
type State = {
  loginVisible: boolean;
  signupVisible: boolean;
  isLoggedIn?: boolean;
  Cookies?: string;
  jwtToken?: string;
  userId?: string;
  isModerator: boolean;
  Uuid?: string;
  IsLegacyUser?: boolean;
  Email?: string;
  DisplayName?: string;
  FirstName?: string;
  LastName?: string;
  City?: any;
  Zip?: any;
  State?: any;
  Mobile?: any;
  Email2?: string;
  UserTypeId?: number;
  blockedThreads?: string[];
  UserNotificationPreferences?: string;
  emailToVerify?: string;
};
type AuthPopupProviderProps = {
  children: React.ReactNode;
};

const AuthPopupStateContext = React.createContext<State>({
  loginVisible: false,
  signupVisible: false,
  IsLegacyUser: false,
  isLoggedIn: false,
  isModerator: false,
  UserNotificationPreferences: "1",
  emailToVerify: "",
});

const AuthPopupDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const isRoleExists = (token: string, roleName: string) => {
  const decodedToken = jwtDecode(token) as any;
  return (
    ROLE_KEY in decodedToken &&
    (decodedToken.Role === roleName ||
      (Array.isArray(decodedToken.Role) &&
        decodedToken.Role.includes(roleName)))
  );
};

const localStorageKey = "AUTH_LOCAL_STORAGE_KEY";
let localAuthState = {
  loginVisible: false,
  signupVisible: false,
  isLoggedIn: false,
  isModerator: false,
};
try {
  localAuthState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
} catch (error) {
  console.error("Parse local state failed");
}
const AuthPopupReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SHOW_LOGIN":
      return {
        ...state,
        loginVisible: true,
        signupVisible: false,
      };
    case "SHOW_LOGIN_AND_VERIFY_EMAIL":
      return {
        ...state,
        loginVisible: true,
        signupVisible: false,
        emailToVerify: action.args,
      };
    case "REMOVE_EMAIL_TO_VERIFY":
      return {
        ...state,
        emailToVerify: "",
      };
    case "SHOW_SIGNUP":
      return {
        ...state,
        loginVisible: false,
        signupVisible: true,
      };
    case "HIDE_AUTH_POPUP":
      return {
        ...state,
        loginVisible: false,
        signupVisible: false,
      };
    case "POST_LOGIN_SUCCESS": {
      cookies.set(
        "idsrv.session",
        action.args.Cookies && action.args.Cookies[0],
        {
          sameSite: "none",
        }
      );
      cookies.set(
        ".AspNetCore.Identity.Application",
        action.args.Cookies && action.args.Cookies[1],
        {
          sameSite: "none",
        }
      );
      return {
        ...state,
        signupVisible: false,
        loginVisible: false,
        isLoggedIn: true,
        jwtToken: action.args.Token,
        isModerator:
          isRoleExists(action.args.Token, roles.MODERATOR) ||
          action.args.UserTypeId === 3 ||
          false,
        userId: action.args.UserId,
        Uuid: action.args.Uuid,
        IsLegacyUser: action.args.IsLegacyUser,
        Email: action.args.Email,
        FirstName: action.args.FirstName,
        LastName: action.args.LastName,
        Zip: action.args.Zip,
        City: action.args.City,
        State: action.args.State,
        Mobile: action.args.Mobile,
        Email2: action.args.Email2 || "",
        UserTypeId: action.args.UserTypeId || 1,
        UserNotificationPreferences:
          action.args.UserNotificationPreferences || "1",
      };
    }
    case "LOG_OUT":
      return {
        signupVisible: false,
        loginVisible: false,
        isLoggedIn: false,
        isModerator: false,
      };
    case "SOFT_LOGOUT":
      return {
        signupVisible: false,
        isLoggedIn: false,
        loginVisible: true,
        isModerator: false,
      };
    case "POST_UPDATE_SUCCESS":
      return {
        ...state,
        FirstName: action.args.FirstName,
        LastName: action.args.LastName,
        IsLegacyUser: action.args.IsLegacyUser,
        UserNotificationPreferences: action.args.UserNotificationPreferences,
        Zip: action.args.Zip,
        City: action.args.City,
        State: action.args.State,
        Mobile: action.args.Mobile,
        Email2: action.args.Email2 || "",
        Uuid: action.args.Uuid,
        Email: action.args.Email,
      };
    case "RESET_AUTH":
      return {
        ...state,
        FirstName: "",
        LastName: "",
        Zip: "",
        City: "",
        State: "",
        Mobile: "",
        Email2: "",
        Uuid: "",
        Email: "",
        isLoggedIn: false,
        emailToVerify: "",
      };
    case UPDATE_SMS_PREF:
      return {
        ...state,
        UserNotificationPreferences: action.args,
      };
    default:
      return state;
  }
};

const AuthPopupContextProvider = ({ children }: AuthPopupProviderProps) => {
  const [state, dispatch] = React.useReducer(AuthPopupReducer, localAuthState);
  React.useEffect(() => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...state,
        loginVisible: false,
        signupVisible: false,
      })
    );
  });
  return (
    <AuthPopupStateContext.Provider value={state}>
      <AuthPopupDispatchContext.Provider value={dispatch}>
        {children}
      </AuthPopupDispatchContext.Provider>
    </AuthPopupStateContext.Provider>
  );
};
const useAuthPopupState = () => {
  const context = React.useContext(AuthPopupStateContext);
  if (context === undefined) {
    throw new Error(
      "useAuthPopupContext must be used within a AuthPopupContextProvider"
    );
  }
  return context;
};

const useAuthPopupDispatch = () => {
  const context = React.useContext(AuthPopupDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAuthPopupDispatch must be used within a AuthPopupContextProvider"
    );
  }
  return context;
};

export { AuthPopupContextProvider, useAuthPopupState, useAuthPopupDispatch };
