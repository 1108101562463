import "core-js/stable";
import "whatwg-fetch";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { environmentVariableList } from "./config/env";

TagManager.initialize({
  gtmId: environmentVariableList.REACT_APP_GTM_ID || "",
});

const container = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="745793039253-5gor7lppt1ldkpp89sbh6ce1amq9c8d0.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </BrowserRouter>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
