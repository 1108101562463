import "./RumbleOnFinance.css";

import * as React from "react";

import { Col, Row } from "antd";

import { Helmet } from "react-helmet";

export const RumbleOnFinance: React.FC = () => {
  return (
    <div className="container">
      {/* <Helmet>
        <title>RumbleOn | RumbleOn Finance</title>
        <meta
          name="description"
          content="The Terms of Use to use RumbleOn Classifieds and buy or list a vehicle for sale online."
        />
        <meta
          property="og:title"
          content="RumbleOn Classifieds | Terms of Use"
        />
        <meta
          property="og:description"
          content="The Terms of Use to buy or list a vehicle on RumbleOn Classifieds."
        />
        <meta
          property="og:image"
          content="https://go.rumbleon.com/hubfs/Classifieds%20OG/Core/terms-of-use.png"
        />
      </Helmet> */}
      <div style={{ textAlign: "center" }}>
        <h1 className="mainHeading">Opt-In Language</h1>
      </div>
      {/* <div>
        <span className="pageBorderTop" />
        <span className="pageBorderBottom" />
      </div> */}

      <div className="mainWrapper">
        <Row type="flex" justify="center">
          <Col xs={22}>
            <div className="legal">
              <p className="slug">RumbleOn Finance, LLC</p>
              <p>What you need to know with pre-qualification requests.</p>
              <p>
                We must first get your permission before sharing your personal
                financial information with outside, unaffiliated companies. By
                opting in, you are aware and consent to RumbleOn, Inc. and
                RumbleOn Finance, LLC to share your information with
                participating preferred dealer partners. and RumbleOn Finance,
                LLC.”) If you so consent (i.e. “opt-in’) to sharing your
                personal information, the information that may share with the
                preferred dealer partners may include [your full name, address,
                phone number(s), email]
              </p>
              <p>
                If you so consent (i.e. “opt-in’) to sharing your personal
                information, you are aware and allow RumbleOn, Inc and RumbleOn
                Finance, LLC to share your information with participating
                preferred dealer partners for the preferred dealer partners to
                market to you, and such marketing may include the preferred
                dealer partners’ own marketing or other marketing unrelated to
                RumbleOn, Inc.
              </p>
              <p>
                If you do not opt-in, we will not share your information with
                participating preferred dealer partners. We may, however,
                provide to you information regarding dealers nearest to you for
                you to visit with or without a contract purchase pre-approval
                certificate. Contract purchase pre-approval(s) from us are valid
                for the purchase of one personal use vehicle only and are valid
                for 30 days from the date issued, provided you are approved when
                completing your application with a dealer and request that
                RumbleOn Finance purchase the contract for a particular vehicle
                at one of the preferred dealer partners listed at RumbleOn.com.
                Dealers offering contract purchase pre-approval through RumbleOn
                Finance, LLC will display a link/button that offers contract
                purchase pre-approval. Otherwise, a dealer may not be offering
                contract purchase through RumbleOn Finance, LLC.
              </p>
              <p>
                The terms of our contract purchase pre-approval are estimates
                and not final and subject to change when your request for
                financing is completed at a participating dealer. There is no
                guarantee that RumbleOn will purchase a contract from a
                participating dealer once the dealer submits your credit
                application for purchase by RumbleOn Finance.
              </p>
              <p className="slug">
                &copy; 2020 RumbleOn, Inc., All Rights Reserved – RumbleOn Terms
                of Use August 3, 2020
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RumbleOnFinance;
