//@ts-nocheck
import React, { FC, createContext, useContext, useReducer } from "react";

type GeolocationState = {
  location: number[];
};

type GeolocationAction = {
  type: typeof SET_LOCATION;
  payload: number[];
};

const GeolocationContext = createContext<
  Partial<{
    state: GeolocationState;
    dispatch: (action: GeolocationAction) => void;
  }>
>({});

export const SET_LOCATION = "SET_LOCATION",
  SET_COORDINATES = "SET_COORDINATES";

//[lon, lat]
const initialState: GeolocationState = {
  userLocation: [], //user current location
  location: [], //address entered
};

const GeolocationReducer = (
  state: GeolocationState,
  action: GeolocationAction
) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};

export const GeolocationContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(GeolocationReducer, initialState);

  return (
    <GeolocationContext.Provider value={{ state, dispatch }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocationContext = () => {
  const context = useContext(GeolocationContext);
  if (context === undefined) {
    throw new Error("useGeolocationContext is undefined");
  }
  return context;
};
