import { ClosestDealersResult, Maybe } from "../generated/graphql";

import React from "react";

type State = {
  center?: {
    lat: any;
    lng: any;
  };
};

type Action = { type: "SET_CENTER"; args: State };

type Dispatch = (action: Action) => void;
type GlobalFlowContextProps = {
  children: React.ReactNode;
};

const GlobalFlowStateContext = React.createContext<State | undefined>(
  undefined
);

const GlobalFlowDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);
const localStorageKey = "GLOBAL_LOCAl_STORAGE_KEY";

let localGlobalFlowState = {};
try {
  localGlobalFlowState = JSON.parse(
    localStorage.getItem(localStorageKey) || "{}"
  );
} catch (error) {
  console.error("Parse local state failed");
}
const GlobalFlowContextReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_CENTER":
      return {
        ...state,
        ...action.args,
      };
    default:
      return state;
  }
};

const GlobalFlowContextProvider = ({ children }: GlobalFlowContextProps) => {
  const [state, dispatch] = React.useReducer(
    GlobalFlowContextReducer,
    localGlobalFlowState
  );
  React.useEffect(() => {
    // save  flow info to local storage
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state]);
  return (
    <GlobalFlowStateContext.Provider value={state}>
      <GlobalFlowDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalFlowDispatchContext.Provider>
    </GlobalFlowStateContext.Provider>
  );
};

const useGlobalFlowState = () => {
  const context = React.useContext(GlobalFlowStateContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalFlowState must be used within a GlobalFlowContextProvider"
    );
  }
  return context;
};

const useGlobalFlowDispatch = () => {
  const context = React.useContext(GlobalFlowDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalFlowDispatch must be used within a GlobalFlowContextProvider"
    );
  }
  return context;
};

export { GlobalFlowContextProvider, useGlobalFlowState, useGlobalFlowDispatch };
