import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import { Modal } from "antd";
import React from "react";

const AcceptOfferSuccessModal: React.FC = () => {
  const dispatch = useModalFlowDispatch();
  const state = useModalFlowState();
  const { showAcceptOfferSuccessModal } = state;
  return (
    <div>
      <Modal
        visible={showAcceptOfferSuccessModal}
        closable
        footer={null}
        onCancel={() =>
          dispatch({
            type: "HIDE_ACCEPT_OFFER_SUCCESS_MODAL",
            args: {
              showAcceptOfferSuccessModal: false,
            },
          })
        }
      >
        <h3>
          Thank you for Accepting your Offer, You will be contacted by
          Phone/Email you provided.
        </h3>
      </Modal>
    </div>
  );
};

export default AcceptOfferSuccessModal;
