const hasValidCharactersLength = (value: string) => value.length >= 6;

const hasOneLowercaseCharacter = (value: string) => {
  const regex = /^(?=.*[a-z]).+$/;
  return regex.test(value);
};

const hasOneUppercaseCharacter = (value: string) => {
  const regex = /^(?=.*[A-Z]).+$/;

  return regex.test(value);
};

const hasOneNumeric = (value: string) => {
  const regex = /^(?=.*\d).+$/;

  return regex.test(value);
};

const validatePasswordPattern = (value: string) => {
  return {
    validLength: hasValidCharactersLength(value),
    oneLowerCase: hasOneLowercaseCharacter(value),
    oneUpperCase: hasOneUppercaseCharacter(value),
    oneNumeric: hasOneNumeric(value),
  };
};


const isLengthExceededForName = (value: string) => value.length > 50;

const hasExclamationCharacter = (value: string) => {
  
  return value.indexOf('!') > 0 || value.indexOf('[') > 0 || value.indexOf(']') > 0 || value.indexOf('`') > 0;
};

const nameStartsLetter = (value: string) => {
  const regex = /^[A-Za-z]/;

  return regex.test(value);
};
const validateFirstAndLastNamePattern = (value: string) => {
  return {
    validLength: isLengthExceededForName(value),
    splCharCheck: hasExclamationCharacter(value),
    startsWithLetter : nameStartsLetter(value)
  };
};

export const validateName = (value: string) => {

  let message = "";
  if (value !== undefined) {
    const result = validateFirstAndLastNamePattern(value);
    if (result.validLength) {
      message = "Names cannot contain more than 50 characters";
    }
    if (!result.startsWithLetter) {
      message.length > 0
        ? (message += ", Names must begin with a letter of the alphabet")
        : (message += "Names must begin with a letter of the alphabet");
    }
    if (result.splCharCheck) {
      message.length > 0
        ? (message += ", Special characters !, [, ], and ` are not allowed in names")
        : (message += "Special characters !, [ , ], and ` are not allowed in names");
    }
}
  return message;
}

export const validatePassword = (value: string) => {
  let message = "";

  if (value !== undefined) {
    const result = validatePasswordPattern(value);

    if (!result.validLength) {
      message += "six characters";
    }
    if (!result.oneLowerCase) {
      message.length > 0
        ? (message += ", one lowercase character")
        : (message += "one lowercase character");
    }
    if (!result.oneUpperCase) {
      message.length > 0
        ? (message += ", one uppercase character")
        : (message += "one uppercase character");
    }
    if (!result.oneNumeric) {
      message.length > 0
        ? (message += ", one numeric character")
        : (message += "one numeric character");
    }
    if (message.length > 0) {
      message += ".";
    }
  }

  return message;
};
