export const breakpoints = {
  sm: "580px",
  md: "875px",
  mid: "1488px",
  lg: "1800px",
};

export const splitDealership = (arr: any) => {
  const dealershipObjects: any = [];
  console.log(arr, "arr");
  arr.map((item: any) => {
    const lat = item._source.latitude;
    const lon = item._source.longitude;
    const address = item._source.address1;
    const stateName = item._source.stateName;
    const logoURL = item._source.dealerLogoImgUrl;
    const phone = item._source.phone;
    JSON.parse(item?._source.dealerAliases).map((alias: any) => {
      const finalDealerObject = {
        ...alias,
        dealerWebsite: alias.url,
        accountName: alias.name,
        accountId: item._source.accountId,
        creditUrl: item._source.creditUrl ? item._source.creditUrl : "",
        address1: address,
        location: { lat, lon },
        phone: phone,
        zip: item._source.zip,
        stateName: stateName,
        dealerLogoImgUrl: logoURL,
        website: item._source.website,
        latitude: lat,
        longitude: lon,
      };
      dealershipObjects.push(finalDealerObject);
    });
  });

  return dealershipObjects;
};

export const splitDealershipFromData = (arr: any) => {
  const dealershipObjects: any = [];
  console.log(arr, "arr");
  arr.map((item: any) => {
    const lat = item.latitude;
    const lon = item.longitude;
    const address = item.address1;
    const stateName = item.stateName;
    const logoURL = item.dealerLogoImgUrl;
    const phone = item.phone;
    JSON.parse(item?.dealerAliases).map((alias: any) => {
      const finalDealerObject = {
        ...alias,
        dealerWebsite: alias.url,
        accountName: alias.name,
        accountId: item.accountId,
        creditUrl: item.creditUrl ? item.creditUrl : "",
        address1: address,
        location: { lat, lon },
        phone: phone,
        zip: item.zip,
        stateName: stateName,
        dealerLogoImgUrl: logoURL,
        website: item.website,
        latitude: lat,
        longitude: lon,
      };
      dealershipObjects.push(finalDealerObject);
    });
  });

  return dealershipObjects;
};

export const colorList = [
  {
    colorId: 1,
    colorName: "Black",
  },
  {
    colorId: 2,
    colorName: "Blue",
  },
  {
    colorId: 3,
    colorName: "Red",
  },
  {
    colorId: 4,
    colorName: "Brown",
  },
  {
    colorId: 5,
    colorName: "White",
  },
  {
    colorId: 6,
    colorName: "Gray",
  },
  {
    colorId: 7,
    colorName: "Silver",
  },
  {
    colorId: 8,
    colorName: "Green",
  },
  {
    colorId: 9,
    colorName: "Orange",
  },
  {
    colorId: 10,
    colorName: "Yellow",
  },
  {
    colorId: 11,
    colorName: "Gold",
  },
  {
    colorId: 12,
    colorName: "Purple",
  },
  {
    colorId: 13,
    colorName: "Maroon",
  },
  {
    colorId: 14,
    colorName: "Teal",
  },
  {
    colorId: 15,
    colorName: "Beige",
  },
  {
    colorId: 16,
    colorName: "Tan",
  },
  {
    colorId: 17,
    colorName: "Copper",
  },
];

export const titleStatusList = [
  {
    titleStatus: "Clean",
    titleStatusId: 1,
  },
  {
    titleStatus: "Junk",
    titleStatusId: 2,
  },
  {
    titleStatus: "Total Loss",
    titleStatusId: 3,
  },
  {
    titleStatus: "Lemon",
    titleStatusId: 4,
  },
  {
    titleStatus: "Grey Market",
    titleStatusId: 5,
  },
  {
    titleStatus: "Flooded",
    titleStatusId: 6,
  },
  {
    titleStatus: "Reconstructed",
    titleStatusId: 7,
  },
  {
    titleStatus: "Non-Actual Miles",
    titleStatusId: 8,
  },
  {
    titleStatus: "Salvage",
    titleStatusId: 9,
  },
  {
    titleStatus: "Other",
    titleStatusId: 10,
  },
];

export const listingStatusList = [
  {
    id: 1,
    title: "Incomplete",
  },
  {
    id: 2,
    title: "In Review",
  },
  {
    id: 3,
    title: "Approved",
  },
  {
    id: 4,
    title: "Sold",
  },
  {
    id: 5,
    title: "Denied",
  },
  {
    id: 6,
    title: "Deleted",
  },
  {
    id: 7,
    title: "Deleted",
  },
  {
    id: 8,
    title: "Cash Offer Accepted",
  },
  {
    id: 9,
    title: "Published",
  },
  {
    id: 10,
    title: "Expired",
  },
  {
    id: 11,
    title: "Denied",
  },
  {
    id: 12,
    title: "In Review",
  },
];

export const roles = {
  MODERATOR: "Moderator",
  SAFEX_ADMIN: "SafeXchange Admin",
};

export const ROLE_KEY = "Role";

export const ERROR_MESSAGE = {
  default: "Something went wrong, please try again.",
  badZip: "The zip code you entered is invalid.",
  cashofferbeingAppraised:
    "A vehicle with this VIN is already in our appraisal process. Feel free to reach us at (573) 298-4851 for any questions.",
  beingAppraised:
    "A vehicle with this VIN is already in our appraisal process.",
  limitExceeded: "Please wait 5 seconds before trying again.",
  fetchFailed: "Failed to fetch",
  requestFailed: "Unable to send request. Please try again.",
  badVin: "THE LETTERS I, O, AND Q ARE NOT ACCEPTED.",
};
