import * as React from "react";

import { Button, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../../context/AuthPopupContext";

import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";



const TopRightMenu: React.FC = () => {
  const dispatch = useAuthPopupDispatch();
  const { isLoggedIn } = useAuthPopupState();
  const history = useHistory();
  return (
    <React.Fragment>
      {/* Desktop */}
      <Menu
        mode="horizontal"
        css={css`
          font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          font-weight: 400;
          color: #2b2b2b;
          border-bottom: none !important;
          .ant-menu-item-selected {
            border-bottom: 2px solid transparent;
            color: rgba(0, 0, 0, 0.65);
          }
          
        `}
      >
        {isLoggedIn && (
          <Menu.Item
            css={css`
              border-bottom: 2px solid transparent !important;
            `}
          >
            <Link to="/my-garage">My Garage</Link>
          </Menu.Item>
        )}

        {isLoggedIn && (
          <Menu.Item
            css={css`
              border-color: transparent !important;
            `}
          >
            <Button
              css={css`
                background: black;
                color: white;
              `}
              onClick={(_) => {
                history.push("/");
                dispatch({
                  type: "LOG_OUT",
                });
              }}
            >
              Log Out
            </Button>
          </Menu.Item>
        )}
        {!isLoggedIn && (
          <Menu.Item
            css={css`
              .ant-menu-item-selected {
                border-bottom: 2px solid transparent !important;
                color: #2b2b2b;
              }
              :hover {
                border-bottom: 2px solid transparent !important;
              }
            `}
            onClick={(_) =>
              dispatch({
                type: "SHOW_LOGIN",
              })
            }
          >
            Log in
          </Menu.Item>
        )}
        {!isLoggedIn && (
          <Menu.Item
            css={css`
              background: white;
              color: #2b2b2b;
              border: 2px solid #4c4c4c !important;
              border-radius: 5px;
              :hover {
                color: white !important;
                background: #2b2b2b !important;
                border-color: #2b2b2b !important;
              }
            `}
            onClick={(_) =>
              dispatch({
                type: "SHOW_SIGNUP",
              })
            }
          >
            Create Account
          </Menu.Item>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default TopRightMenu;
