import * as React from "react";

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { ApolloLink, concat } from "apollo-link";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../context/AuthPopupContext";

import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import { environmentVariableList } from "../../config/env";
import { tokenFormatter } from "../../utils/format";

interface Props {
  children: React.ReactChildren | React.ReactElement;
}

const ApolloWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAuthPopupDispatch();
  const { jwtToken, isLoggedIn } = useAuthPopupState();
  const apolloClient = () => {
    const cache = new InMemoryCache();
    const link = createHttpLink({
      uri: environmentVariableList.REACT_APP_GRAPHQL_ENDPOINT,
    });
    const authMiddleWare = new ApolloLink((operation, forward) => {
      if (jwtToken && isLoggedIn) {
        const parsedToken = tokenFormatter(jwtToken);
        const expTime = new Date(parsedToken.exp * 1000);
        const currTime = new Date();
        if (expTime.getTime() < currTime.getTime()) {
          // remove token if expired
          dispatch({
            type: "SOFT_LOGOUT",
          });
        } else {
          operation.setContext({
            headers: {
              authorization: `Bearer ${jwtToken}`,
            },
          });
        }
      }
      return forward(operation);
    });
    const client = new ApolloClient({
      cache,
      link: concat(authMiddleWare, link),
    });
    return client;
  };
  return <ApolloProvider client={apolloClient()}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
