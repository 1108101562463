import * as React from "react";

import { Col, Menu, Row } from "antd";

import { Link } from "react-router-dom";
// import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import { environmentVariableList } from "../../../config/env";
import queryString from "query-string";
import { useCashOfferContext } from "../../../context/CashOfferContext";

const MobileFooter: React.FC = () => {
  const { SubMenu } = Menu;
  const handleClick = (e: any) => {
    console.log("click ", e);
  };
  const { state } = useCashOfferContext();
  const ItemTypeId = state?.decodeData?.ItemTypeId;
  const dealerBrand = queryString.parseUrl(window.location.search).query
    ?.utm_source;
  return (
    <div
      css={css`
        @media (max-width: 767px) {
          width: 100%;
        }
        @media (min-width: 767px) {
          display: none;
        }
      `}
    >
      <div
        css={css`
          border-bottom: 2px solid white;
          padding: 5%;
          text-align: center;
          a {
            color: white !important;
            font-size: 16px;
            font-weight: bold;
            font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          }
        `}
      >
        <Row gutter={14}>
          <Col span={12}>
            <Link to="/buy">Buy</Link>
          </Col>
          <Col span={12}>
            <Link
              to={{
                pathname:
                  (state.dealerBrandType === "campaign" && ItemTypeId === 1) ||
                    window.location.pathname === "/how-to/sell-your-vehicle-1"
                    ? "/how-to/sell-your-vehicle-1"
                    : (state.dealerBrandType === "campaign" &&
                      ItemTypeId === 3) ||
                      window.location.pathname === "/how-to/sell-your-boat-1"
                      ? "/how-to/sell-your-boat-1"
                      : "/how-to/sell-your-vehicle",
                state: {
                  refresh: true,
                },
              }}
            >
              Cash Offer
            </Link>
          </Col>
        </Row>
        <Row
          gutter={14}
          css={css`
            margin-top: 3%;
          `}
        >
          {/* <Col span={12}>
            {" "}
            <Link to="/list-your-vehicle/step-1">List</Link>
          </Col> */}
          {/* <Col span={12}>
            <Link to="/finance">Finance</Link>
          </Col> */}
          <Col span={12}>
            <Link to="/dealerships">Find a Store</Link>
          </Col>
        </Row>
        <Row
          gutter={14}
          css={css`
            margin-top: 3%;
          `}
        >
          {/* <Col span={12}>
            <Link to="/dealerships">Find a Dealer</Link>
          </Col>
          <Col span={12}>
            <a
              href={environmentVariableList.DEALER_PORTAL_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Dealer Portal
            </a>
          </Col> */}
        </Row>
      </div>
      <div css={css``}>
        <Menu
          css={css`
            color: white;
            border: none;
            font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
            .ant-menu-item:hover,
            .ant-menu-item-active,
            .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
            .ant-menu-submenu-active,
            .ant-menu-submenu-title:hover {
              color: white;
            }
            .ant-menu-item-selected > a,
            .ant-menu-item-selected > a:hover {
              color: white;
            }
            .ant-menu-submenu-selected {
              color: white;
            }
            .ant-menu-vertical .ant-menu-item::after,
            .ant-menu-vertical-left .ant-menu-item::after,
            .ant-menu-vertical-right .ant-menu-item::after,
            .ant-menu-inline .ant-menu-item::after {
              border: none;
            }
            i.ant-menu-submenu-arrow {
              position: absolute;
              right: 0 !important;
            }
            .ant-menu-submenu-vertical
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-vertical-left
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-vertical-right
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-inline
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::before,
            .ant-menu-submenu-vertical
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::after,
            .ant-menu-submenu-vertical-left
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::after,
            .ant-menu-submenu-vertical-right
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::after,
            .ant-menu-submenu-inline
              > .ant-menu-submenu-title
              .ant-menu-submenu-arrow::after {
              position: absolute;
              width: 6px;
              height: 1.5px;
              background: #fff;
              background-image: linear-gradient(
                to right,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0)
              );
              background-image: none;
              border-radius: 0 !important;
              font-size: 22px !important;
            }
            .ant-menu-submenu-title {
              padding-left: 0 !important;
              :active {
                color: white;
              }
            }
            .ant-menu-submenu > .ant-menu {
              background: #2b2b2b;
              color: white !important;
            }
            a {
              color: white;
              font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
            }
            li.ant-menu-item {
              padding-left: 15px !important;
              margin: 0 !important;
              font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              font-weight: bold;
            }
            .ant-menu-submenu-title {
              font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              font-weight: bold;
              font-size: 16px;
            }
            .ant-menu-item > a:hover {
              color: white !important;
            }
          `}
          // theme={"dark"}
          onClick={handleClick}
          style={{ width: "100%", background: "#2B2B2B" }}
          mode="inline"
        // onSelect={selected}
        >
          <SubMenu key="sub1" title={" About RumbleOn"}>
            {/* <Menu.Item key="1">
              <Link to="/list-your-vehicle/step-1">Selling a Motorcycle</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/list-your-vehicle/step-1">Selling an ATV</Link>
            </Menu.Item> */}
            <Menu.Item key="4">
              <a href="/about-us">About Us</a>
            </Menu.Item>
            {/* <Menu.Item key="5">
              <a
                href="https://help.rumbleon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </Menu.Item> */}
            <Menu.Item key="3">
              <a href="/contact-us">Contact Us</a>
            </Menu.Item>
            <Menu.Item key="faq">
              <a href="/faq">FAQ</a>
            </Menu.Item>
            {/* @todo get link */}
            {/* <Menu.Item key="6">
              <a
                href="https://www.rumbleswag.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get RumbleOn Swag
              </a>
            </Menu.Item> */}
            <Menu.Item key="7">
              <a
                href="https://go.rumbleon.com/careers-at-rumbleon"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </Menu.Item>
            <Menu.Item key="8">
              <a
                href="https://investors.rumbleon.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Investors
              </a>
            </Menu.Item>
            <Menu.Item key="9">
              <a
                href="https://explore.rumbleon.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </Menu.Item>
            <Menu.Item key="10">
              <a
                href="https://go.rumbleon.com/rally-with-rumbleon-upcoming-events"
                target="_blank"
                rel="noopener noreferrer"
              >
                Events
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
};

export default MobileFooter;
