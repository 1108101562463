import * as React from "react";

import Cookies from "js-cookie";
import { css } from "@emotion/core";

const USER_CONSENT_COOKIE_KEY = "cookie_consent_is_true";
const USER_CONSENT_COOKIE_EXPIRE_DATE =
  new Date().getTime() + 365 * 24 * 60 * 60;
const CookieConsent: React.FC = () => {
  const [cookieConsentIsTrue, setCookieConsentIsTrue] = React.useState(true);
  const onClick = (e: any) => {
    e.preventDefault();
    console.log("here to set cookies", "true");
    if (!cookieConsentIsTrue) {
      console.log("here to set cookies", "true");
      Cookies.set(USER_CONSENT_COOKIE_KEY, "true", {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    }
  };

  React.useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === "true";
    setCookieConsentIsTrue(consentIsTrue);
  }, []);
  return (
    <>
      {!cookieConsentIsTrue && (
        <div className="cookieContainer">
          <div className="cookieText">
            <a href="/privacy?tab=#34" className="textHover">
              We use cookies and browser activity to improve your experience,
              personalize content and ads, and analyze how our sites are used.
              For more information on how we collect and use this information,
              please review our Privacy Policy.
            </a>
            <div>
              <button
                className="acceptButton"
                css={css`
                  cursor: pointer;
                `}
                onClick={onClick}
              >
                I Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
