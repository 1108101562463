import * as React from "react";

import { Button, Form, Input, Spin, notification } from "antd";
import { Mutation, UpdateCashOfferUserInput } from "../../generated/graphql";

import { FormComponentProps } from "antd/lib/form";
import { css } from "@emotion/core";
import { loader } from "graphql.macro";
import urlParams from "query-string";
import { useAuthPopupDispatch } from "../../context/AuthPopupContext";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { validatePassword } from "../../utils/validate";

const UPDATE_CASHOFFER_USER = loader("./updateCashOfferUserMutation.graphql");

interface Props extends FormComponentProps {}

const ResetPasswordPage: React.FC<Props> = ({ form }) => {
  const history = useHistory();
  const authDispatch = useAuthPopupDispatch();
  const [updatePassword, updatePasswordResult] = useMutation<Mutation>(
    UPDATE_CASHOFFER_USER,
    {
      onCompleted: (data) => {
        if (data && data.updateCashOfferUser) {
          if (
            history.location.state &&
            history.location.state.goBack &&
            history.location.state.goBack === true
          ) {
            history.goBack();
          } else {
            history.push("/buy");
            authDispatch({ type: "SHOW_LOGIN" });
          }

          notification["success"]({
            message: "Password update successfully",
            description: "Please login with your new password",
          });
        } else {
          notification["error"]({
            message: "Error",
            description:
              "We are not able to complete this, please try again later",
          });
        }
      },
      onError: (_) => {
        notification["error"]({
          message: "Error",
          description:
            "We are not able to complete this, please try again later",
        });
      },
    }
  );

  const [confirmPasswordDirty, setConfirmPasswordDirty] = React.useState<
    boolean
  >(false);

  const params = window.location.search;
  console.log(params, urlParams.parse(params).uuid);
  const UserUuid = urlParams.parse(params).uuid as string;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields((error, values) => {
      if (!error) {
        const updateInput: UpdateCashOfferUserInput = {
          Password: values.password,
          UserUuid: UserUuid,
        };
        updatePassword({
          variables: {
            input: updateInput,
          },
        });
      }
    });
  };
  const handlePasswordConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmPasswordDirty(confirmPasswordDirty || !!value);
  };
  const confirmToNextPassword = (_: any, value: any, callback: any): void => {
    const validationResult = validatePassword(value);
    if (value && validationResult.length > 0) {
      callback(`Must contain at least ${validationResult}`);
    }
    if (value && confirmPasswordDirty) {
      form.validateFields(["passwordConfirm"], {
        force: true,
      });
    }
    callback();
  };
  const confirmToFirstPassword = (_: any, value: any, callback: any): void => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Please confirm you enter the same password");
    } else {
      callback();
    }
  };

  if (!UserUuid || UserUuid.length < 3) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 500px;
        `}
      >
        <div
          css={css`
            // border: 2px solid lightgray;
            // padding: 20px;
            h3 {
              text-align: center;
              font-size: 18px;
              font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              font-weight: bold;
              color: #2b2b2b;
            }
            p {
              text-align: center;
              font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
            }
          `}
        >
          <h3>Oops, this seems our fault</h3>
          <p>Looks like you have landed on a wrong page</p>
          <p>
            Check your inbox for the right email for creating your password.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 500px;
      `}
    >
      <div
        css={css`
          margin: auto;
          width: 30% !important;
          min-height: 60vh;
          h2 {
            text-align: center;
          }
          > div {
            width: 100% !important;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;

          @media (max-width: 786px) {
            width: 100% !important;
          }
        `}
      >
        <h2>Please enter your new password</h2>
        <Spin spinning={updatePasswordResult.loading}>
          <Form
            colon={false}
            hideRequiredMark={true}
            onSubmit={handleSubmit}
            css={css`
              margin: auto;
            `}
          >
            <Form.Item label="Password">
              {form.getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    validator: confirmToNextPassword,
                  },
                ],
              })(
                <Input.Password
                  visibilityToggle
                  size="large"
                  placeholder="Enter your password"
                />
              )}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {form.getFieldDecorator("passwordConfirm", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    validator: confirmToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  visibilityToggle
                  onBlur={handlePasswordConfirmBlur}
                  size="large"
                  placeholder="Confirm your password"
                />
              )}
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              css={css`
                display: block !important;
                margin: auto;
                text-transform: uppercase;
                width: 200px;
                height: 40px;
              `}
            >
              Submit
            </Button>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default Form.create<Props>({ name: "reset-password-form" })(
  ResetPasswordPage
);
