import * as React from "react";

import { Col, Row } from "antd";

import { ReactComponent as FacebookIcon } from "../images/facebook-f-brands.svg";
import { ReactComponent as InstagramIcon } from "../images/instagram-brands.svg";
import { Link } from "react-router-dom";
import MobileFooter from "./MobileFooter";
import { ReactComponent as PinterestIcon } from "../images/pinterest-brands.svg";
import { Query } from "../../../generated/graphql";
import { ReactComponent as RumbleOnLogo } from "../images/rumbleon-logo-white.svg";
import { ReactComponent as TwitterIcon } from "../images/twitter-brands.svg";
import { ReactComponent as YoutubeIcon } from "../images/youtube-brands.svg";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import { environmentVariableList } from "../../../config/env";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";

const STOCK_MARKET_VALUE = loader("./stockMarketValues.graphql");

const Footer: React.FC = () => {
  const [count, setCount] = React.useState<any>();
  const stockQueryResult = useQuery<Query>(STOCK_MARKET_VALUE, {
    skip: count > 0,
  });
  const stock = stockQueryResult.data && stockQueryResult.data.getstockflow;
  React.useEffect(() => {
    const result =
      (stockQueryResult.data && stockQueryResult.data.getstockflow) || 0;
    if (result !== 0) {
      setCount(result);
    }
  }, [stockQueryResult]);
  return (
    <div>
      <div
        css={css`
          padding: 20px 12%;
          background: #2b2b2b;
          svg {
            height: 20px;
            width: 100%;
          }
          /* Mobile style */
          @media (max-width: 992px) {
            padding: 20px 5%;
          }
        `}
      >
        {/* image */}
        <Row
          css={css`
            display: flex;
            justify-content: flex-start;
            svg {
              height: 66px;
              width: 210px;
            }
            @media (max-width: 767px) {
              justify-content: center;
            }
          `}
        >
          <Link to="/">
            <RumbleOnLogo />
          </Link>
        </Row>
        {/* socialmedia */}
        <Row
          css={css`
            @media (min-width: 768px) {
              display: none !important;
            }
            @media (max-width: 302px) {
              padding: 0 5% !important;
            }
            padding: 20px 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 66px;
            & a {
              display: flex;
              // flex-direction:row;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
              width: 35px;
              height: 35px;
              // font-size: 15px;
              padding: 2%;
              border-radius: 50%;
              border: 1px solid white;
              background-color: transparent;
              line-height: 2;
              color: white;
              text-align: center;
            }
            a:first-child {
              padding-left: 2.5%;
              padding-right: 2.5%;
            }
            /* Mobile style */
            @media (max-width: ${breakpoints.md}) {
              text-align: center;
              justify-content: space-around !important;
              align-items: center !important;
              height: 40px;
              width: 100%;
              padding: 0 15%;
              a {
                margin-bottom: 5px;
                padding: 2% !important;
              }
            }
          `}
        >
          <a
            href="https://www.facebook.com/rumbleon.official"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://twitter.com/rumbleon_"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="twitter"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.instagram.com/rumbleon.official/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="instagram"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCUm6-vHwq6ppsWo5Aj_99tA"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="youtube"
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://www.pinterest.com/ready2rumbleon/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="pinterest"
          >
            <PinterestIcon />
          </a>
        </Row>
        <MobileFooter />
        <Row
          css={css`
            margin-top: 3%;
            /* Mobile style */
            @media (max-width: 767px) {
              display: none !important;
            }
          `}
        >
          <Col span={17} css={css``}>
            <Row
              gutter={16}
              css={css`
                margin-left: 0 !important;
                margin-right: 0 !important;
                height: 50px !important;
                border-bottom: 2px solid white;
                a {
                  color: white !important;
                  // font-size:16px;
                  font-weight: bold;
                  padding-top: 1% !important;
                  font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                }
                a:hover {
                  cursor: pointer;
                  color: white !important;
                }
              `}
            >
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <Link to="/buy">Buy</Link>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <Link to="/how-to/sell-your-vehicle">Cash Offer</Link>
              </Col>
              {/* <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <Link to="/list-your-vehicle/step-1">List</Link>
              </Col> */}
              {/* <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <Link to="/finance">Finance</Link>
              </Col> */}
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a href="/dealerships" target="blank">
                  Find a Store
                </a>
              </Col>
              {/* <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <Link to="/dealerships">Find a Dealer</Link>
              </Col> */}
              {/* <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a
                  href={environmentVariableList.DEALER_PORTAL_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dealer Portal
                </a>
                @find the correct link
              </Col> */}
            </Row>
            <Row
              css={css`
                color: white !important;
                // font-size:16px;
                font-weight: bold;
                padding-top: 3% !important;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              `}
            >
              About RumbleOn
            </Row>
            <Row
              css={css`
                color: white !important;
                // font-size:16px;
                padding-top: 2% !important;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                a {
                  color: white !important;
                  // font-size:16px;
                  font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                }
                a:hover {
                  cursor: pointer;
                  color: white !important;
                }
              `}
            >
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Link to="/about-us">About Us</Link>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <a href="/contact-us">Contact Us</a>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Link to="/faq">FAQ</Link>
              </Col>
              {/* <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <a
                  href="https://help.rumbleon.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </Col> */}
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a
                  href="https://go.rumbleon.com/careers-at-rumbleon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a
                  href="https://investors.rumbleon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Investors
                </a>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a
                  href="https://explore.rumbleon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </Col>
              <Col
                span={4}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                `}
              >
                <a
                  href="https://go.rumbleon.com/rally-with-rumbleon-upcoming-events"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Events
                </a>
              </Col>
            </Row>

            {/* <Row
              css={css`
                color: white !important;
                // font-size:16px;
                padding-top: 2% !important;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                a {
                  color: white !important;
                  // font-size:16px;
                  font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                }
                a:hover {
                  cursor: pointer;
                  color: white !important;
                }
              `}
            >
              <Col span={4}>
                <Link
                  css={css`
                    // margin-top: 10%;
                    width: 95%;
                  `}
                  to="/list-your-vehicle/step-1"
                >
                  Selling an ATV
                </Link>
              </Col>

              <Col span={4}>
                <Link
                  css={css`
                    // margin-top: 10%;
                  `}
                  to="/list-your-vehicle/step-1"
                >
                  Motorcycle
                </Link>
              </Col>

              <Col span={4}>
                <a
                  href="https://www.rumbleswag.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={css`
                    margin-top: 10%;
                  `}
                >
                  Get RumbleOn Swag
                </a>
              </Col>
              <Col span={4}></Col>

              <Col span={4}></Col>

              <Col span={4}></Col>
            </Row> */}
            {/* <Row
              css={css`
                color: white !important;
                // font-size:16px;
                font-weight: bold;
                padding-top: 7% !important;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              `}
            >
              RumbleOn Finance
            </Row> */}
            {/* <Row
              css={css`
                color: white !important;
                // font-size:16px;
                padding-top: 2% !important;
                // display:flex;
                // flex-direction:row;
                // align-items:center;
                justify-content: space-between;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                a {
                  color: white !important;
                  // font-size:16px;
                  font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                }
                a:hover {
                  cursor: pointer;
                  color: white !important;
                }
              `}
            >
              <Col
                span={8}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  text-align: start !important;
                `}
              >
                <Link to="/finance">Get Pre-qualified</Link>
              </Col>
              <Col
                span={8}
                css={css`
                  padding-top: 1% !important;
                  padding-left: 0 !important;
                  pading-right: 0 !important;
                  text-align: start !important;
                `}
              >
                <a
                  href="https://go.rumbleon.com/about-rumbleon-finance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RumbleOn Finance
                </a>
              </Col>
            </Row> */}
          </Col>
          <Col
            span={7}
            css={css`
              @media (max-width: 767px) {
                display: none !important;
              }
            `}
          >
            <Row
              css={css`
                // padding: 20px 10%;
                display: flex;
                justify-content: start;
                align-items: center;
                height: 66px;
                & a {
                  display: flex;
                  // flex-direction:row;
                  justify-content: center;
                  align-items: center;
                  margin-right: 15px;
                  width: 35px;
                  height: 35px;
                  // font-size: 15px;
                  padding: 2%;
                  border-radius: 50%;
                  border: 1px solid white;
                  background-color: transparent;
                  line-height: 2;
                  color: white;
                  text-align: center;
                  @media (max-width: 1175px) {
                    margin-right: 5px;
                  }
                }
                a:first-child {
                  padding-left: 2.5%;
                  padding-right: 2.5%;
                  margin-left: 15px;
                }
                /* Mobile style */
                @media (max-width: ${breakpoints.md}) {
                  // text-align: center;
                  // justify-content: space-around !important;
                  // align-items: center !important;
                  // height: 40px;
                  // width: 100%;
                  // padding: 0 15%;
                  a {
                    margin-bottom: 5px;
                    margin-right: 5px;
                    padding: 2% !important;
                  }
                }
              `}
            >
              <a
                href="https://www.facebook.com/rumbleon.official"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/rumbleon_"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="twitter"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.instagram.com/rumbleon.official/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.youtube.com/channel/UCUm6-vHwq6ppsWo5Aj_99tA"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="youtube"
              >
                <YoutubeIcon />
              </a>
              <a
                href="https://www.pinterest.com/ready2rumbleon/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="pinterest"
              >
                <PinterestIcon />
              </a>
            </Row>
            {/* <Row
              css={css`
                height: 100%;
                margin: 3% 0 0 15px;
                @media (min-width: ${breakpoints.lg}) {
                  width: 70%;
                }
              `}
            >
              <Col span={12} css={css`padding 0 !important;`}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://itunes.apple.com/us/app/rumbleon/id1219198993?ls=1&amp;mt=8&amp;__hstc=243639578.ca192f6d11b47144c36188d5809dfd08.1528130817072.1528130817072.1528130817072.1&amp;__hssc=243639578.2.1528130817072&amp;__hsfp=1860276752"
                >
                  <img
                    src={require("../images/apple_store_badge.png")}
                    // style={{ width: "100%" }}
                    css={css`
                      width: 100%;
                      max-width: 200px !important;
                    `}
                    // mode="fit"
                    alt="RumbleOn App | Get Our App for iOS"
                  />
                </a>
              </Col>
              <Col span={12} css={css`padding 0 !important;`}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.halcyontek.rumbleon.login&amp;__hstc=243639578.ca192f6d11b47144c36188d5809dfd08.1528130817072.1528130817072.1528130817072.1&amp;__hssc=243639578.2.1528130817072&amp;__hsfp=1860276752"
                >
                  <img
                    src={require("../images/google-play-badge.png")}
                    // style={{ width: "100%", marginLeft: 10 }}
                    css={css`
                      width: 100%;
                      max-width: 200px !important;
                      margin-left: 5%;
                    `}
                    // mode="fit"
                    alt="RumbleOn App | Get Our App for Android"
                  />
                </a>
              </Col>
            </Row> */}
            <Row
              css={css`
                height: 100%;
                margin: 30px 0 0 15px;
                @media (min-width: ${breakpoints.lg}) {
                  width: 70%;
                }
              `}
            >
              <Col
                css={css`
                  /* Desktop style */
                  @media (min-width: ${breakpoints.md}) {
                    // margin-left:15px;
                    // margin-top:15%;
                  }
                `}
              >
                {stock && (
                  <div>
                    <Row
                      css={css`
                        border: 1px solid white;
                        padding: 2%;
                        p {
                          font-family: aktiv-grotesk, arial, Helvetica,
                            sans-serif !important;
                          font-weight: bold;
                          font-size: 16px;
                          color: white;
                          margin-bottom: 0;
                        }
                      `}
                    >
                      <p>NASD: RMBL</p>
                      <p
                        css={css`
                          @media (max-width: 794px) {
                            font-size: 15px !important;
                          }
                          color: #d4d4d4 !important;
                        `}
                      >
                        RumbleOn, Inc. - Class B
                      </p>
                      <p
                        css={css`
                          font-size: 24px !important;
                          @media (max-width: 794px) {
                            font-size: 20px !important;
                          }
                        `}
                      >
                        ${Number.parseFloat(stock.price).toFixed(2)}
                        <span
                          css={css`
                            font-size: 16px;
                            margin-left: 1%;
                            @media (max-width: 794px) {
                              font-size: 15px !important;
                            }
                          `}
                        >
                          {Number.parseFloat(stock.changepercent).toFixed(2)}%{" "}
                          {Number.parseFloat(stock.change).toFixed(2)}
                        </span>
                      </p>
                    </Row>
                    <p
                      css={css`
                        text-align: right;
                        color: #d4d4d4;
                        font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                      `}
                    >
                      DELAYED BY 20 MINUTES
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          css={css`
            margin-top: 2%;
            @media (min-width: 767px) {
              display: none !important;
            }
          `}
        >
          {/* <Col
            xs={24}
            md={12}
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-top: 5%;
              height: 100%;
            `}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://itunes.apple.com/us/app/rumbleon/id1219198993?ls=1&amp;mt=8&amp;__hstc=243639578.ca192f6d11b47144c36188d5809dfd08.1528130817072.1528130817072.1528130817072.1&amp;__hssc=243639578.2.1528130817072&amp;__hsfp=1860276752"
            >
              <img
                src={require("../images/apple_store_badge.png")}
                style={{ width: 150 }}
                // mode="fit"
                alt="RumbleOn App | Get Our App for iOS"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.halcyontek.rumbleon.login&amp;__hstc=243639578.ca192f6d11b47144c36188d5809dfd08.1528130817072.1528130817072.1528130817072.1&amp;__hssc=243639578.2.1528130817072&amp;__hsfp=1860276752"
            >
              <img
                src={require("../images/google-play-badge.png")}
                style={{ width: 150 }}
                // mode="fit"
                alt="RumbleOn App | Get Our App for Android"
              />
            </a>
          </Col> */}
          <Col
            xs={24}
            md={12}
            css={css`
              margin-top: 5%;
            `}
          >
            {stock && (
              <Row
                css={css`
                  border: 1px solid white;
                  padding: 2%;
                  p {
                    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
                    font-weight: bold;
                    color: white;
                    margin-bottom: 0;
                  }
                `}
              >
                <p>NASD: RMBL</p>
                <p
                  css={css`
                    color: #d4d4d4 !important;
                  `}
                >
                  RumbleOn, Inc. - Class B
                </p>
                <p
                  css={css`
                    font-size: 24px !important;
                  `}
                >
                  ${Number.parseFloat(stock.price).toFixed(2)}
                  <span
                    css={css`
                      font-size: 16px;
                      margin-left: 1%;
                    `}
                  >
                    {Number.parseFloat(stock.changepercent).toFixed(2)}%{" "}
                    {Number.parseFloat(stock.change).toFixed(2)}
                  </span>
                </p>
              </Row>
            )}
            <p
              css={css`
                text-align: right;
                font-size: 14px;
                color: #d4d4d4;
                font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
              `}
            >
              DELAYED BY 20 MINUTES
            </p>
          </Col>
        </Row>
        <Row>
          <div
            css={css`
          display:flex; 
          flex-direction: row;
          color: white !important;
          font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          height: 100%;
          text-align: start;
          margin-top: 5%;
          & p {
            margin-bottom: 5px; !important;
          }
          @media (max-width: 767px) {
            flex-direction: column;
            justify-content:center;
            align-items:center;
            & p{
              margin-left:0 !important;
              text-align:center;
            }
          }
        `}
          >
            <p> © 2020 RumbleOn Inc. All rights reserved. </p>
            <p
              css={css`
                margin-left: 10% !important;
              `}
            >
              By using this service, you accept our
              <Link
                css={css`
                  border-bottom: 1px solid white;
                  color: white;
                  margin-left: 3px;
                  :hover {
                    color: white;
                  }
                `}
                to="/termsofuse"
              >
                Terms of Use
              </Link>{" "}
              and
              <Link
                css={css`
                  border-bottom: 1px solid white;
                  color: white;
                  margin-left: 3px;
                  :hover {
                    color: white;
                  }
                `}
                to="/privacy"
              >
                Privacy Policy
              </Link>
            </p>
            <a
              css={css`
                text-decoration: underline;
                color: white;
                text-underline-position: under;
                margin-left: 10%;
                :hover {
                  color: white;
                }
                @media (max-width: 767px) {
                  margin-left: 0 !important;
                }
              `}
              href="/termsofuse?#119"
              target="_blank"
            >
              Do not sell my personal info.
            </a>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
