import * as React from "react";

import { Icon, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import AboutV5 from "./AboutV5";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import queryString from "query-string";
import { useAuthPopupState } from "../../../context/AuthPopupContext";
import { useCashOfferContext } from "../../../context/CashOfferContext";

const { SubMenu } = Menu;

const MainMenu: React.FC = () => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [modalVisible, setModalVisble] = React.useState<boolean>(false);
  let location = useLocation();
  const { isLoggedIn } = useAuthPopupState();

  const { state } = useCashOfferContext();
  const ItemTypeId = state?.decodeData?.ItemTypeId;
  const dealerBrand = queryString.parseUrl(window.location.search).query
    ?.utm_source;
  const handleClose = () => {
    setModalVisble(false);
  };

  React.useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/buy")) {
      setSelectedKeys(["buy"]);
    } else if (pathname.includes("/sell")) {
      setSelectedKeys(["sell"]);
    } else if (pathname.includes("/list")) {
      setSelectedKeys(["listing"]);
    } else if (pathname.includes("/finance")) {
      setSelectedKeys(["finance"]);
    } else if (pathname.includes("/dealerships")) {
      setSelectedKeys(["dealerships"]);
    } else if (pathname.includes("/insurance")) {
      setSelectedKeys(["insurance"]);
    } else {
      setSelectedKeys([]);
    }
  }, [location.pathname]);

  return (
    <Menu
      mode="horizontal"
      css={css`
        border: none !important;
        .ant-menu-item > a {
          color: #2b2b2b !important;
          font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          font-weight: 400;
          font-size: 18px;
        }
        line-height: 25px !important;
        .ant-menu-item {
          border: none !important;
          background: white !important;
          text-align: center;
        }
        .ant-menu-item-selected > a {
          border-bottom: 2px solid #1b51cf !important;
          font-weight: bold !important;
        }
        .ant-menu-submenu {
          color: #2b2b2b !important;
          font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          font-weight: 400;
          font-size: 18px !important;
          margin: 0 !important;
          top: 3px !important;
          bottom: 0 !important;
        }
        @media (max-width: ${breakpoints.md}) {
          display: none;
        }
      `}
      selectedKeys={selectedKeys}
    >
      <Menu.Item key="buy">
        <Link to="/buy">Buy</Link>
      </Menu.Item>
      <Menu.Item key="sell">
        <Link
          to={{
            pathname:
              (state.dealerBrandType === "campaign" && ItemTypeId === 1) ||
              window.location.pathname === "/how-to/sell-your-vehicle-1"
                ? "/how-to/sell-your-vehicle-1"
                : (state.dealerBrandType === "campaign" && ItemTypeId === 3) ||
                  window.location.pathname === "/how-to/sell-your-boat-1"
                ? "/how-to/sell-your-boat-1"
                : "/how-to/sell-your-vehicle",
            state: {
              refresh: true,
            },
          }}
        >
          Cash Offer
        </Link>
      </Menu.Item>
      {!isLoggedIn && (
        <Menu.Item key="listing">
          <Link to="/list-your-vehicle-for-a-dealer">Dealer</Link>
        </Menu.Item>
      )}
      {/* <Menu.Item>
        <Link to="/rumbleon-inventory">List</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="finance">
        <Link to="/finance">Get Pre-qualified</Link>
      </Menu.Item> */}

      <Menu.Item key="dealerships">
        <a href="/dealerships" target="blank">
          Find a Store
        </a>
      </Menu.Item>

      <SubMenu
        key="subDesktop"
        title={
          <span>
            About{" "}
            <Icon
              css={css`
                margin-right: 0 !important;
              `}
              type="caret-down"
            />
          </span>
        }
      >
        <Menu.Item key="1">
          <Link to="/about-us" onClick={handleClose}>
            About Us
          </Link>
        </Menu.Item>

        <Menu.Item key="2">
          <Link to="/contact-us" onClick={handleClose}>
            Contact Us
          </Link>
        </Menu.Item>

        <Menu.Item key="3">
          <a
            href="https://go.rumbleon.com/careers-at-rumbleon"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            Careers
          </a>
        </Menu.Item>

        <Menu.Item key="4">
          <a
            href="https://investors.rumbleon.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            Investors
          </a>
        </Menu.Item>

        <Menu.Item key="5">
          <a
            href="https://explore.rumbleon.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            Blog
          </a>
        </Menu.Item>

        <Menu.Item key="6" onClick={handleClose}>
          <Link to="/faq">FAQ</Link>
        </Menu.Item>

        <Menu.Item key="7">
          <a
            href="https://go.rumbleon.com/rally-with-rumbleon-upcoming-events"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            Events
          </a>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="insurance">
        <Link to="/insurance">Insurance</Link>
      </Menu.Item>

      {/* <Menu.Item key="liveauctions">
        <a onClick={() => {
          window.open(
            "https://www.ridenowauctions.com",
            "_blank"
          )
        }} >Live Auction</a>
      </Menu.Item> */}
    </Menu>
  );
};

export default MainMenu;
