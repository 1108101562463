import { Col, Modal, Row } from "antd";
import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import React from "react";
import { css } from "@emotion/core";

const FinanceNonTCIStateModal: React.FC = () => {
  const dispatch = useModalFlowDispatch();
  const state = useModalFlowState();
  console.log(state);

  const handleClose = () => {
    console.log(state);
    dispatch({
      type: "HIDE_FINANCE_NON_TCISTATE_MODAL",
      args: {
        showFinanceNonTCIStateModal: false,
      },
    });
  };

  const { showFinanceNonTCIStateModal, closestDealers = [] } = state;
  return (
    <div>
      <Modal
        css={css`
          width: 50% !important;
          @media (max-width: 768px) {
            width: 100% !important;
          }
        `}
        closable
        visible={showFinanceNonTCIStateModal}
        onCancel={handleClose}
        footer={null}
      >
        <div>
          <Row>
            <h3>
              Oops, The state you selected is not qualified for our Finance
            </h3>
            <h4>
              But you can still check out these closest Dealers for Finance
            </h4>
          </Row>

          <Row
            type="flex"
            justify="space-between"
            css={css`
              margin-top: 2%;
              @media (max-width: 768px) {
                width: 100% !important;
              }
            `}
          >
            {closestDealers.map((dealership) => {
              return (
                <Col key={dealership?.DealerId} xs={24} lg={8}>
                  <img
                    src={dealership?.DealerLogo || ""}
                    width={50}
                    height={50}
                  />
                  <h3>{dealership?.DealerName}</h3>
                  <a href={dealership?.DealerPortalUrl || ""}>Visit Website</a>
                </Col>
              );
            })}

            {/* <Col span={8}>Dealer 2</Col>

            <Col span={8}>Dealer 3</Col> */}
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default FinanceNonTCIStateModal;
