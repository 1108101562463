import { ClosestDealersResult, Maybe } from "../generated/graphql";

import React from "react";

type State = {
  showFinancePrequalModal?: boolean;
  showFinanceNonTCIStateModal?: boolean;
  showAcceptOfferSuccessModal?: boolean;
  showCashOfferVideoModal?: boolean;
  showRequestInfoModal?: boolean;
  showShareModal?: boolean;
  closestDealers?: Maybe<ClosestDealersResult>[] | undefined;
  selectedESPage?: number;
  selectedDealershipESPage?: number;
  showAcceptTOUModal?: boolean;
  showAcceptFinanceOptInModal?: boolean;
  showFindYourVINModal?: boolean;
  enablenextButton?: boolean;
};

type Action =
  | { type: "SHOW_FINANCE_MODAL"; args: State | false }
  | { type: "HIDE_FINANCE_MODAL"; args: State | false }
  | { type: "SHOW_FINANCE_NON_TCISTATE_MODAL"; args: State | false }
  | { type: "HIDE_FINANCE_NON_TCISTATE_MODAL"; args: State | false }
  | { type: "SHOW_ACCEPT_OFFER_SUCCESS_MODAL"; args: State | false }
  | { type: "HIDE_ACCEPT_OFFER_SUCCESS_MODAL"; args: State | false }
  | { type: "SHOW_REQUEST_INFO_MODAL"; args: State | false }
  | { type: "HIDE_REQUEST_INFO_MODAL"; args: State | false }
  | { type: "SHOW_SHARE_MODAL"; args: State | false }
  | { type: "HIDE_SHARE_MODAL"; args: State | false }
  | { type: "SET_ES_PAGE"; args: State }
  | { type: "RESET_ES_PAGE"; args: State }
  | { type: "SET_DEALERSHIP_PAGE"; args: State }
  | { type: "RESET_DEALERSHIP_PAGE"; args: State }
  | { type: "SHOW_ACCEPT_TOU"; args: State | false }
  | { type: "HIDE_ACCEPT_TOU"; args: State | false }
  | { type: "SHOW_ACCEPT_FINANCE_OPT_IN_LANGUAGE"; args: State | false }
  | { type: "HIDE_ACCEPT_FINANCE_OPT_IN_LANGUAGE"; args: State | false }
  | { type: "SHOW_CASH_OFFER_VIDEO_MODAL"; args: State | false }
  | { type: "HIDE_CASH_OFFER_VIDEO_MODAL"; args: State | false }
  | { type: "ENABLE_NEXT_BUTTON"; args: State | false }
  | { type: "SHOW_FIND_YOUR_VIN_MODAL"; args: State | false };

type Dispatch = (action: Action) => void;
type ModalFlowContextProps = {
  children: React.ReactNode;
};

const ModalFlowStateContext = React.createContext<State | undefined>(undefined);

const ModalFlowDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);
const localStorageKey = "MODAL_FLOW_LOCAl_STORAGE_KEY";

let localModalFlowState = {};
try {
  localModalFlowState = JSON.parse(
    localStorage.getItem(localStorageKey) || "{}"
  );
} catch (error) {
  console.error("Parse local state failed");
}
const ModalFlowContextReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "ENABLE_NEXT_BUTTON":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_FINANCE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_FINANCE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_FINANCE_NON_TCISTATE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_FINANCE_NON_TCISTATE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_REQUEST_INFO_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_REQUEST_INFO_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_ACCEPT_OFFER_SUCCESS_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_ACCEPT_OFFER_SUCCESS_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_SHARE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_SHARE_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SET_ES_PAGE":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_ACCEPT_TOU":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_ACCEPT_TOU":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_ACCEPT_FINANCE_OPT_IN_LANGUAGE":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_ACCEPT_FINANCE_OPT_IN_LANGUAGE":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_CASH_OFFER_VIDEO_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "HIDE_CASH_OFFER_VIDEO_MODAL":
      return {
        ...state,
        ...action.args,
      };
    case "SHOW_FIND_YOUR_VIN_MODAL":
      return {
        ...state,
        ...action.args,
      };
    default:
      return state;
  }
};

const ModalFlowContextProvider = ({ children }: ModalFlowContextProps) => {
  const [state, dispatch] = React.useReducer(
    ModalFlowContextReducer,
    localModalFlowState
  );
  React.useEffect(() => {
    // save  flow info to local storage
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state]);
  return (
    <ModalFlowStateContext.Provider value={state}>
      <ModalFlowDispatchContext.Provider value={dispatch}>
        {children}
      </ModalFlowDispatchContext.Provider>
    </ModalFlowStateContext.Provider>
  );
};

const useModalFlowState = () => {
  const context = React.useContext(ModalFlowStateContext);
  if (context === undefined) {
    throw new Error(
      "useModalFlowState must be used within a ModalFlowContextProvider"
    );
  }
  return context;
};

const useModalFlowDispatch = () => {
  const context = React.useContext(ModalFlowDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useModalFlowDispatch must be used within a ModalFlowContextProvider"
    );
  }
  return context;
};

export { ModalFlowContextProvider, useModalFlowState, useModalFlowDispatch };
