import * as React from "react";

import { Col, Row } from "antd";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { colors } from "../../constants/styles";
import { css } from "@emotion/core";

export const legalCss = css`
  padding: 5rem 0;

  h1 {
    text-align: center;
    font-size: 35px;
    line-height: 37px;
    font-family: interstate, sans-serif;
    font-weight: 800;
    padding-bottom: 1rem;
  }

  h3 {
    font-family: aktiv-grotesk !important;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-family: aktiv-grotesk !important;
    font-size: 18px;
    font-weight: 400;
  }

  ul {
    list-style-position: initial;
    padding-left: 20px;
    li {
      font-family: aktiv-grotesk !important;
      font-size: 18px;
    }
  }

  a {
    color: ${colors.blue};
  }
`;

export const TermsofUse = () => {
  return (
    <>
      <Helmet>
        <title>RumbleOn | Terms of Use</title>
        <meta
          name="description"
          content="The Terms of Use to use RumbleOn and buy or list a vehicle for sale online."
        />
        <meta property="og:title" content="RumbleOn | Terms of Use" />
        <meta
          property="og:description"
          content="The Terms of Use to buy or list a vehicle on RumbleOn."
        />
      </Helmet>
      <Row type="flex" align="middle" justify="center">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 18 }}
          lg={{ span: 16 }}
          css={legalCss}
        >
          <h1>RumbleOn Terms of Use</h1>
          <p>Last Revision: August 18, 2021</p>
          <p>
            Please read these Terms of Use (“Terms”) carefully. These Terms are
            between you (“user(s)”, “you” or “your”) and RumbleOn, Inc.,
            including all of its wholly owned subsidiaries, such as RumbleOn
            Finance, LLC and RumbleOn Dealers Inc, et al (“RumbleOn”, “we” or
            “our”) and cover your use of the content, services, features and
            tools (collectively the “Services”, as further defined below)
            accessed directly or indirectly through any RumbleOn website or
            mobile website, including without limitation{" "}
            <a
              href="https://www.rumbleon.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.rumbleon.com
            </a>
            ,{" "}
            <a
              href="https://www.wholesaleinc.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.wholesaleinc.com
            </a>
            ,{" "}
            <a
              href="https://www.gotspeed.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.gotspeed.com
            </a>{" "}
            and any other site maintained by RumbleOn (collectively, “Sites”),
            and any web-based or mobile applications (the “Apps”) that reference
            or link to these Terms.
          </p>
          <p>
            You accept and agree to be bound by these Terms and our{" "}
            <a href="/privacy">Privacy Policy</a> (“Privacy Policy”) by
            accessing or using one or all of the Services. The Services are
            available only to people who are at least eighteen (18) years old
            and possess the legal capacity to form a binding agreement with
            RumbleOn. IF YOU DO NOT HAVE SUCH LEGAL CAPACITY, OR IF YOU DO NOT
            AGREE TO ABIDE BY OR BE BOUND BY THESE TERMS AND PRIVACY POLICY,
            PLEASE DO NOT ACCESS, USE OR REGISTER TO USE ANY OF OUR SERVICES.
          </p>
          <p>
            <b>
              Please note that Section 19 contains an arbitration clause and
              class action waiver. You agree to resolve all disputes through
              binding arbitration, and you waive any right to have a judge or
              jury decide those disputes. You also waive your right to
              participate in class actions, class arbitrations, and
              representative actions.
            </b>
          </p>
          <h3>
            <u>1. The Services</u>
          </h3>
          <p>
            The Services are currently designed for users who are interested in
            buying or selling their vehicles. The Services are mainly targeted
            to U.S. residents however, the Services are available to non-U.S.
            residents as well. The Services are currently provided free of
            charge, however RumbleOn reserves the right to charge fees with
            respect to some or all of the Services in the future.
          </p>
          <p>
            The Services governed by these Terms allow (or may allow you in the
            future) to:
          </p>
          <p>
            <b> Buy a Vehicle</b> and additional services associated with the
            purchase, such as certain warranties, by (a) searching our inventory
            of vehicles; (b) holding the vehicle of your choice; (c) using
            trade-in services; (d) estimating your monthly payment (e) applying
            for online vehicle financing; or (f) arranging for an open or closed
            transport of the selected vehicle within the continental United
            States only (please note that if you are not a U.S. resident, you
            shall be solely responsible for the execution and payment of
            import/export approvals and applicable taxes). In certain instance
            we may be prevented from offering certain services to you if you
            live in a particular area or we are subject to restrictions in our
            business with; for example, California Emission Laws prevent us from
            selling any vehicle with less than 7,500 miles to any current
            California resident.
          </p>
          <p>
            <b>Sell Your Vehicle</b> by using our Appraisal Tool. To use the
            Appraisal Tool, you may need to submit (a) photos of your vehicle,
            (b) your vehicle’s VIN number, (c) your vehicle’s mileage, (d)
            description of the vehicle and any other information we may require
            from time to time. The submitted information regarding your vehicle
            is then uploaded to our internal systems, and if we are interested
            in purchasing your vehicle, we will send an email outlining the
            terms of our offer, one of which allows you to use the value of the
            cash offer towards the purchase of another vehicle (trade-in) from
            us.
          </p>
          <p>
            <b>Submit Your Content</b>. One or all of the Services may include
            “Interactive Areas” on which users may connect with each other and
            share information, recommendations and reviews. Any content
            submitted by users to the Interactive Areas (including without
            limitation, such recommendations, reviews, user’s full name, vehicle
            photos or other user proprietary materials, whether or not via the
            Interactive Areas) shall be called “Submissions”.
          </p>

          <p>
            Each Service includes our proprietary content or licensed content of
            others, and may include additional resources such as FAQ and
            support, contact information, videos, text, files, logos, button
            icons, images, data compilations, links, other specialized content,
            technical data, documentation, know-how, specifications materials,
            designs, data, the “look and feel” of the Service, algorithms,
            source and object code, interface, GUI, interactive features,
            related graphics, illustrations, drawings, animations and other
            features obtained from or through the Service (collectively, the
            “Content”).
          </p>
          <p>
            <i>
              THE USE OF THE SERVICES MAY ENABLE USERS TO FACILITATE A
              CONNECTION WITH THIRD PARTIES THAT ARE NOT OWNED BY OR AFFILIATED
              WITH RumbleOn (INCLUDING WITHOUT LIMITATION, THIRD PARTY SERVICE
              PROVIDERS OR OTHER USERS WHO MAY BE INTERESTED IN PURCHASING
              VEHICLES). RUMBLEON DOES NOT OWN, SELL, CONTROL, MANAGE, OPERATE,
              ENDORSE, SPONSOR, AND IS NOT INVOLVED IN ANY MANNER WHATSOEVER
              WITH SUCH THIRD PARTIES. RumbleOn’S SERVICES OFFER AN APPRAISAL
              TOOL, ASSESSING THE VALUE OF CERTAIN VEHICLES BASED ON CERTAIN
              CRITERIA. RumbleOn MAKES NO REPRESENTATIONS REGARDING THE
              APPRAISAL TOOL AND/OR ITS ACCURACY. TO THE EXTENT LEGALLY
              PERMISSIBLE, THE SERVICES AND THE CONTENT AVAILABLE THEREIN ARE
              PROVIDED ON AN “AS IS” BASIS. RumbleOn DISCLAIMS ALL WARRANTIES OF
              ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, AND NON-INFRINGEMENT AS TO THE SERVICES AND THE
              CONTENT OR THE SUBMISSIONS. YOU EXPRESSLY AGREE THAT USE OF THE
              SITE AND THE CONTENT OR THE SUBMISSIONS IS AT YOUR SOLE RISK.
              RumbleOn AND OUR THIRD-PARTY SERVICE PROVIDERS DO NOT REPRESENT OR
              WARRANT THAT THE CONTENT OR SUBMISSIONS ARE ACCURATE, COMPLETE,
              RELIABLE, CURRENT, OR ERROR-FREE. RumbleOn WILL NOT BE LIABLE FOR
              ANY DAMAGE OR LOSS, COST OR EXPENSE INCURRED TO USERS OR ANY THIRD
              PARTY AS A RESULT OF OR IN CONNECTION WITH THE USE OF THE
              SERVICES, THE CONTENT OR THE SUBMISSIONS.
            </i>
          </p>
          <h3>
            <u>2. Limitations of Usage</u>
          </h3>
          <p>
            Except to the extent you were authorized in writing by RumbleOn, you
            may use the Services solely for personal, non-commercial uses (other
            than for the purpose of selling or purchasing a vehicle). You shall
            not use the Services to advertise or promote any product, service or
            business. Without limiting the foregoing, you may not use the
            Services to submit or upload any Submissions or details:
          </p>
          <ul>
            <li>
              If you are not the legal owner of your vehicle or if you do not
              have the legal right to sell such vehicle;
            </li>
            <li>If the vehicle is not registered in your name;</li>
            <li>
              That are fabricated, false, misleading, offensive or
              inappropriate;
            </li>
            <li>If the purpose of doing so is to compete with RumbleOn;</li>
            <li>That are illegal, deceitful or inaccurate; or</li>
            <li>
              That are for, on behalf of, or at the request of another person,
              if such other person would violate any of the above or below
              restrictions by uploading such Submissions.
            </li>
          </ul>
          <p>Additionally, you may not:</p>
          <ul>
            <li>
              Register for multiple Accounts or use the Account of another
              person;
            </li>
            <li>Use the Services or open an account if you are under 18;</li>
            <li>
              Impersonate any person or entity, falsely state or otherwise
              misrepresent yourself or your affiliation with any person or
              entity, or express or imply that RumbleOn endorses you, your
              vehicle or your business in any manner;
            </li>
            <li>
              Use the Services in any way or for any purpose that is immoral,
              unauthorized or against any local, state, federal or any other
              applicable laws of the United States to which you may be subject
              (including without limitation, encouraging harmful practices,
              violence, self-harm, suicide, anorexia, bulimia or the
              solicitation of minors);
            </li>
            <li>
              Upload or submit any content that is racist, sexist, inflammatory,
              defamatory, hateful, harassing, threatening, abusive, profane,
              obscene, vulgar or sexually explicit, false, misleading,
              fraudulent, invasive of RumbleOn’s or another’s privacy or
              publicity rights, infringing of RumbleOn’s or another’s
              intellectual property rights, contains any trade secrets or other
              confidential information, or is otherwise offensive or
              objectionable;
            </li>
            <li>
              Use the Services in any way or for any purpose that harms or
              endangers RumbleOn or its affiliates, agents, resellers,
              licensors, subsidiaries, officers, directors, shareholders,
              employees, sub-contractors, distributors, service providers and/or
              suppliers (collectively, the “RumbleOn Representatives”);
            </li>
            <li>
              Submit any data containing unsolicited promotion, advertising,
              contests or raffles;
            </li>
            <li>
              Attempt to interfere with or disrupt the ability to access the
              Services, the operation of the Services or the servers or networks
              that host them;
            </li>
            <li>
              Remove or disassociate from the Content or the Services any
              restrictions and signs indicating proprietary rights of RumbleOn
              or its licensors, including but not limited to any proprietary
              notices contained in such materials (such as ©, ™, or ®); Bypass
              any measures RumbleOn may use to prevent or restrict access to the
              Services;
            </li>
            <li>
              Bypass any measures RumbleOn may use to prevent or restrict access
              to the Services;
            </li>
            <li>
              Use any data mining, robots, or other data collection methods in
              connection with the Services (including the creation of
              databases);
            </li>
            <li>
              Make available in connection with the Services any virus, worm,
              Trojan horse, spyware, bug, malware, or any other harmful content;
            </li>
            <li>
              Use the Services for any purpose for which they are not intended;
              or
            </li>
            <li>
              Infringe or violate any of the provisions of these Terms or the
              Privacy Policy.
            </li>
          </ul>

          <p>
            PLEASE BE AWARE THAT YOUR BREACH OF THE TERMS OR PRIVACY POLICY MAY
            ALSO EXPOSE YOU TO CIVIL OR CRIMINAL LIABILITY.
          </p>
          <h3>
            <u>3. Your Account</u>
          </h3>
          <p>
            In order to enjoy the full scope of the Services, you are required
            to register and open an account (the “Account”), by (i) providing
            RumbleOn with certain details or (ii) using an existing social
            network account (e.g., Facebook, Google, or Twitter) (for further
            information, see the Privacy Policy). You must provide RumbleOn with
            accurate and complete information when opening an Account and keep
            it updated at all times. You may not open more than one Account. You
            are responsible for the activities that occur under your Account and
            for maintaining the confidentiality of your password and log-in
            details.
          </p>
          <p>
            The Account is yours and you shall not allow anyone else to use it
            without RumbleOn’s prior written approval. You must immediately
            notify RumbleOn of any unauthorized use of your Account or breach of
            its security. RumbleOn will not be responsible for any damage which
            is caused to you or others if you do not act in accordance with
            these Terms or the Privacy Policy.
          </p>
          <p>
            If you wish to cancel the Account, you may email RumbleOn at{" "}
            <a href="mailto:notices@rumbleon.com"> notices@rumbleon.com</a> and
            we will cancel it within a reasonable period of time. Once the
            Account is cancelled, its information may be deleted or unavailable
            and RumbleOn will not be responsible for any loss in this respect.
          </p>

          <p>
            Any of RumbleOn’s users (including unregistered users) may contact
            RumbleOn by submitting their request on the{" "}
            <a href="/contact-us">Contact Us</a> page form, which may require
            their name and e-mail address.
          </p>

          <h3 id="119">
            <u>4. Information Sharing</u>
          </h3>
          <p>
            RumbleOn collects, stores, and safeguards information, some of which
            could be considered Personally Identifiable Information (“PII”) per
            US privacy law. To learn more about how we protect your information,
            please refer to our <a href="/privacy">RumbleOn Privacy Policy</a>.
            RumbleOn may share PII data with RumbleOn Finance, other third-party
            lenders, and vehicle dealers only when you submit a request for
            pre-qualification for financing or when you request information for
            a dealer’s vehicle that is featured on a RumbleOn Site or mobile
            app. You can opt-out of data sharing at any time at the{" "}
            <a href="/termsofuse?#119">Do Not Sell My Personal Info</a> page on
            the RumbleOn Site or mobile app.
          </p>

          <p>
            RumbleOn may also release PII when it’s release is required to
            comply with the law, enforce our site policies, or protect ours or
            others’ rights, property or safety.
          </p>
          <p>
            Non-Personally Identifiable Information may be provided to other
            parties for marketing, advertising, or other uses.
          </p>
          <h3>
            <u>5. Payment Services</u>
          </h3>
          <p>
            RumbleOn uses online payment services which are rendered by
            independent contractors. Any credit card details which users provide
            RumbleOn with respect to the Services (e.g. for the purchase of
            vehicles) are transferred to these online payment services
            (including without limitation, PayPal, Venmo, Stripe, or others).
            The user is solely responsible for using these online payment
            services and is also required to abide to the terms specified by the
            applicable online payment service provider. The service provider’s
            terms of use and privacy policy, not RumbleOn’s Terms and Privacy
            Policy, govern your use of the online payment services.
          </p>
          <h3>
            <u>6. Typographical Errors</u>
          </h3>

          <p>
            RumbleOn and its third-party service providers are not responsible
            for typographical errors or omissions relating to pricing, text,
            photography or any other information included in the Services. In
            the event that any RumbleOn product is mistakenly listed at an
            incorrect price, RumbleOn reserves the right to refuse or cancel any
            orders placed for products listed at the incorrect price. RumbleOn
            reserves the right to refuse or cancel such orders whether or not
            the order has been confirmed and payment was made. If your order is
            cancelled, RumbleOn shall issue a refund in the form of the original
            payment.
          </p>
          <h3>
            <u>7. Intellectual Property</u>
          </h3>
          <p>
            All of the intellectual property rights (including without
            limitation, inventions, patents and patent applications, trademarks,
            trademark applications, trade names, service marks, copyrightable
            materials, domain names and trade secrets, whether or not registered
            or capable of being registered) in or to the Services and the
            Content (other than the Submissions) (“Intellectual Property”) are
            owned by RumbleOn and/or licensed to it and are protected by
            applicable copyright and other intellectual property laws and
            international conventions and treaties.
          </p>
          <p>
            Certain automotive content displayed within this website (or
            application, or mobile application) may be used pursuant to licenses
            or other such arrangements with others and is protected under the
            United States and international copyright law. Any unauthorized use,
            reproduction, distribution, recording or modification of this
            content is strictly prohibited. The intellectual property rights in
            or to the Submissions (including the photos of your vehicle) must be
            owned by you or licensed to you and not conflict with any third
            party’s rights or any applicable laws. Users must receive all
            consents and authorizations required by law regarding the use of
            their respective Submissions (including without limitation, under
            applicable privacy laws or regulations). YOU AGREE THAT ALL
            INFORMATION UPLOADED OR SUBMITTED BY YOU (EXCLUDING PERSONALLY
            IDENTIFIABLE INFORMATION AND FINANCIAL INFORMATION) IS
            NONCONFIDENTIAL AND MAY BECOME PUBLICLY AVAILABLE ON OR THROUGH THE
            RumbleOn SERVICES.
          </p>
          <p>
            You are granted a personal, non-exclusive, non-commercial,
            non-transferable, non-sublicensable and fully revocable limited
            license to use our Content and Services in accordance with the Terms
            and Privacy Policy. All rights not expressly granted to you under
            the Terms or the Privacy Policy are reserved by RumbleOn and/or its
            licensors. Among other restricted actions, for example: (i) you may
            not modify, reproduce, republish, frame, download, transmit,
            distribute, rent, lease, loan, sell, assign, license, sublicense,
            reverse engineer, publicly display or create derivative works based
            on the Content or Services without RumbleOn’s prior written consent,
            (ii) you are not allowed to use the RumbleOn trademarks or
            third-party trademarks appearing on or through the Services without
            our prior written consent.
          </p>
          <p>
            You grant RumbleOn a perpetual, non-exclusive, royalty-free,
            worldwide license to publicly display, communicate, distribute,
            host, publicly perform, publish, reproduce, make modifications or
            derivative works, store and to use any of your Submissions{" "}
            <b>in connection with the RumbleOn Services,</b> in any media
            formats and through any media channels known today and developed in
            the future for the purpose of operating, marketing, promoting and
            improving the Services. You also grant RumbleOn permission to
            attribute you in connection with your Submissions. You hereby waive
            any rights of privacy or publicity in this respect.{" "}
            <b>
              For clarity, you are not transferring ownership of any marks you
              own, and you may request that RumbleOn no longer use any marks you
              own in the event you terminate your use of the Services.
            </b>
          </p>
          <p>
            THE UPLOADING OF SUBMISSIONS IS AT YOUR SOLE RISK AND
            RESPONSIBILITY. RumbleOn WILL NOT BE LIABLE FOR ANY RESULT
            (INCLUDING LOSS, DAMAGE, COST) ARISING FROM, OR CONNECTED TO, THE
            SUBMISSIONS (INCLUDING THEIR USE BY OTHER USERS).
          </p>
          <p>
            Even though RumbleOn has no obligation to screen, edit, delete or
            monitor Submissions, it reserves the right to do so at its sole
            discretion and without giving any prior notice, including in the
            following cases: (i) in order to conform with legal requirements or
            respond to any legal adjudication or process; (ii) in order to
            safeguard the Services or ensure your compliance with these Terms or
            our Privacy Policy; and/or (iii) in order to protect and secure the
            interests, rights and property of the RumbleOn Representatives. In
            addition, RumbleOn may limit the size and storage spaces available
            for Submissions.
          </p>
          <h3>
            <u>
              8. Notice and Takedown - Digital Millennium Copyright Act
              Compliance
            </u>
          </h3>

          <p>
            If you believe that any content displayed on any of the Services (i)
            is inappropriate, offensive, violent, sexually inappropriate; or
            (ii) infringes your copyrighted work, you may send us a notice to{" "}
            <a href="mailto:notices@rumbleon.com"> notices@rumbleon.com</a>.
          </p>

          <p>
            According to the U.S. Digital Millennium Copyright Act, 17 U.S.C.
            Sec. 512(c)(3), notices relating to copyright infringement must
            include the following information: (a) a description of your
            copyrighted work; (b) proof that you are the owner of the
            copyrighted work; (c) a description of the material that you believe
            to be infringing your copyrighted work and that should be removed or
            blocked; (d) your contact details; and (e) a statement that the
            information you provided is accurate, and that you are the copyright
            owner or authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed.
          </p>
          <p>
            Once we receive the notice, we may take actions at our discretion,
            including the removal of the alleged infringing or offensive
            material.
          </p>
          <h3>
            <u>
              9. Linking to our Service(s) and Links to Third Party Websites or
              Services
            </u>
          </h3>
          <p>
            You are free to establish a link to any of our Services on your
            rightfully owned properties so long as the link does not state or
            imply any connection or approval of RumbleOn to your website,
            products or services or portray RumbleOn in a false or offensive
            manner.
          </p>
          <p>
            One or all of the RumbleOn Services may contain links to
            non-RumbleOn websites or services for your convenience only; they
            are not under our control and we do not endorse or assume any
            responsibility for their content, information or functionality. Your
            access or use of any non-RumbleOn websites or services or reliance
            upon their content is at your own risk and such linked sites or
            services are not governed by these Terms or our Privacy Policy.
          </p>
          <p>
            You agree that RumbleOn is not responsible or liable, directly or
            indirectly, for any damage or loss of any kind caused or alleged to
            be caused, by or in connection with your use of or reliance upon any
            information, services, content, products or other materials
            available on or through such linked sites or resources, or any
            damage or loss relating to your interaction or dealings with third
            parties. RumbleOn reserves the right to terminate any link at any
            time at its sole discretion.
          </p>
          <h3>
            <u>10. Disclaimer of Warranties</u>
          </h3>
          <p>
            TO THE EXTENT LEGALLY PERMISSIBLE, ANY AND ALL OF THE SERVICES AND
            THE CONTENT ARE PROVIDED ON AN “AS-IS” BASIS. RumbleOn DISCLAIMS ALL
            WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO WARRANTIES OF
            TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AND THOSE
            ARISING FROM A COURSE OF DEALING OR USAGE OF TRADE.
          </p>
          <p>
            IN ADDITION, IN NO EVENT SHALL RumbleOn OR A RumbleOn REPRESENTATIVE
            BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DECISION MADE OR
            ACTION TAKEN BY YOU OR ANY OTHER PARTY IN CONNECTION WITH THE
            SERVICES.
          </p>
          <p>
            RumbleOn and RumbleOn REPRESENTATIVES DISCLAIM ALL WARRANTIES AND
            CONDITIONS REGARDING THE USE OF ANY AND ALL SERVICES, INCLUDING BUT
            NOT LIMITED TO THE OPERATION, ACCURACY, RELIABILITY, COMPLETENESS,
            QUALITY OR SUITABILITY OF THE INFORMATION OR CONTENT DISPLAYED ON OR
            THROUGH THEM.
          </p>
          <p>
            RumbleOn and RumbleOn REPRESENTATIVES ARE NOT RESPONSIBLE FOR ANY
            CONSEQUENCES TO YOU OR OTHERS THAT MAY RESULT FROM TECHNICAL
            PROBLEMS OR ERRORS.
          </p>
          <p>
            INASMUCH AS SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OR
            LIMITATIONS SET FORTH HEREIN, THE FULL EXTENT OF THE ABOVE
            EXCLUSIONS AND LIMITATIONS MAY NOT APPLY.
          </p>

          <h3>
            <u>11. Limitation of Liability</u>
          </h3>
          <p>
            To the extent permitted by law, in no event will RumbleOn or the
            RumbleOn Representatives be liable to you or to any third party for:
            (1) indirect, consequential, special, incidental, exemplary, or
            punitive damages, under any legal theory (including, without
            limitation, by tort, negligence, contract, or other), arising from
            or connected to the Services, Terms, Content, or Privacy Policy; (2)
            any costs of procuring any substitute goods, technology, or
            services; or (3) any damages whatsoever that in the aggregate exceed
            the greater of (a) the amount actually paid by you (if any) to
            RumbleOn for use of the applicable Services in the immediately
            previous twelve (12) month period or (b) US $100.00.
          </p>
          <p>
            Some jurisdictions do not allow the exclusions or limitations set
            forth above, in which case, the full extent of the above exclusions
            and limitations may not apply.
          </p>
          <h3>
            <u>12. Indemnification</u>
          </h3>
          <p>
            You agree to defend, indemnify and hold harmless RumbleOn and
            RumbleOn Representatives from and against any actual or threatened
            claim, loss, liability, proceeding, third-party discovery demand,
            investigation, damages, costs, and expenses, including reasonable
            attorney’s fees, arising out of or in connection with: (i) your use
            or misuse of the Content or Services; (ii) your conduct in
            connection with the Services or with other third parties via the
            Services; (iii) your Submissions; (iv) your violation of these Terms
            or Privacy Policy; (v) your violation of any third-party rights,
            including, without limitation, any intellectual property right or
            privacy right of such third party; and (vi) any damage, of any sort,
            you may cause to any third party with relation to the Submissions,
            Content, or Services.
          </p>
          <p>
            RumbleOn reserves the right to assume the exclusive defense and
            control of any matter which is subject to indemnification by you,
            which will not excuse your indemnity obligations and in which event
            you will fully cooperate with RumbleOn in asserting any available
            defense. You agree not to settle any matter subject to an
            indemnification by you without first obtaining RumbleOn’s prior
            express written approval.
          </p>
          <p>
            You shall and do hereby waive California Civil Code §1542 or any
            other similar law of any jurisdiction, which says in substance: “A
            general release does not extend to claims that the creditor does not
            know or suspect to exist in his or her favor at the time of
            executing the release, which if known by him or her, must have
            materially affected his or her settlement with the debtor”.
          </p>
          <h3>
            <u>13. Advertisements</u>
          </h3>
          <p>
            RumbleOn may accept advertisements from third party advertisers or
            others. RumbleOn makes no warranties or representations concerning
            such advertisements. You understand and agree that you are not
            entitled to any compensation or remuneration with respect to these
            advertisements.
          </p>
          <h3>
            <u>14. Children’s Restricted Use</u>
          </h3>
          <p>
            RumbleOn reserves the right to request proof of age at any stage so
            that it can verify that minors or children under the age of eighteen
            (18) are not using any of the Services. If RumbleOn becomes aware
            that a person under the age of eighteen is using any of the
            Services, it will prohibit and block such person from accessing or
            using the Services.
          </p>
          <h3>
            <u>15. Notification of Terms of Use Revisions</u>
          </h3>
          <p>
            RumbleOn reserves the right to make changes to its Terms of Use at
            any time and for any reason, so please re-visit this page
            frequently. The Terms of Use versions are differentiated by the
            “Last Revision” date indicated at the top of the Terms of Use
            document. If RumbleOn makes material changes to the Terms of Use,
            then RumbleOn will notify you by providing a link to the updated
            Terms of Use in a special “clickwrap” login modal placed on the
            RumbleOn Site. This special clickwrap login modal is designed
            specifically to direct the User to view and accept the updated Terms
            of Use in order to complete login successfully and regain access to
            the Site. The special clickwrap login modal will only appear once if
            the User accepts the updated Terms of Use, until the next time the
            Terms of use is updated. The updated Terms of Use will become
            effective immediately upon acceptance, as indicated by the User in
            the special clickwrap login modal. If any of the Services or
            portions of the Terms of Use must be amended in order to comply with
            any legal requirements, the amendments may take effect immediately
            or as required by law and without any prior notice.
          </p>
          <h3>
            <u>16. RumbleOn Services Discontinuance</u>
          </h3>
          <p>
            RumbleOn reserves the right to stop, temporarily or permanently, the
            operation of any and all Services without notice, at any time. All
            information (ours or yours) made available on the applicable Service
            may be removed or deleted and you are required to make your own copy
            of any information you submit to RumbleOn. RumbleOn will not be
            liable to you or to any third party for any changes, suspension, or
            discontinuance of the Service(s) or any malfunctions that may occur
            in connection thereto.
          </p>
          <h3>
            <u>17. Suspension: Termination</u>
          </h3>
          <p>
            If you do not comply with the Terms and Privacy Policy, RumbleOn may
            suspend or terminate your Account. If, for any reason, you no longer
            consent to these Terms or Privacy Policy, please stop using the
            Services (and, to the extent you are a registered user, please
            cancel your Account as stated in Section 3 above) and this will be
            your sole remedy in such circumstances.
          </p>
          <p>Upon termination, by you or us:</p>
          <ul>
            <li>
              You will cease any further use of the Site, Services and any
              information that was made available to you prior to the
              termination;
            </li>
            <li>
              All rights granted to you under the Terms and Privacy Policy will
              automatically terminate;
            </li>
            <li>
              Certain data (e.g. Content, Submissions, etc.) may be deleted and
              you will have no right or claim in this respect; and
            </li>
          </ul>
          <p>
            The provisions of these Terms that by their nature must survive the
            termination shall so survive. Without limiting the generality of the
            forgoing, the Intellectual Property, Disclaimer of Liability,
            Limitation of Liability, Indemnification, Jurisdiction and “General”
            sections will survive the termination of these Terms.
          </p>

          <h3>
            <u>18. General</u>
          </h3>
          <p>
            (a) These Terms constitute the entire agreement between you and
            RumbleOn regarding your use of any and all of the Services as
            detailed herein, and supersedes any prior agreements between you and
            us relating to such use; (b) These Terms do not, and shall not be
            construed to create any relationship, partnership, joint venture,
            employer-employee, agency, or franchisor-franchisee relationship
            between you and RumbleOn; (c) Any heading, caption or section title
            contained in these Terms or our Privacy Policy is inserted only as a
            matter of convenience; (d) The failure of either party to exercise
            or enforce any right or provision of these Terms or any prior
            version of these Terms shall not constitute a waiver of such right
            or provision in that or any other instance; (e) If any provision of
            these Terms shall be deemed unlawful, void or for any reason
            unenforceable, then that provision shall be amended as possible to
            comply with the applicable law. This shall not affect the validity
            and enforceability of any remaining provisions; (f) We may assign
            any right or obligation under the Terms or Privacy Policy without
            restriction, but you may do so only after receiving our express
            written consent; (g) Any amendment to the Terms and Privacy Policy
            must be done in writing and signed by us and you; (h) All
            correspondence between you and RumbleOn shall be in English.
          </p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE
            ARISING OUT OF OR RELATED TO THE SERVICES OR CONTENT MUST COMMENCE
            WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
            SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
          </p>
          <h3>
            <u>19. Arbitration</u>
          </h3>
          <p>
            In the event that you and RumbleOn are unable to resolve any dispute
            or claim with one another, you and RumbleOn each agree to resolve
            any and all disputes and claims through binding individual
            arbitration, unless you expressly reject this arbitration provision
            in writing and within 30 days in accordance with the last paragraph
            of this section 19 below. “Disputes and claims” shall be broadly
            construed to include past, current, and/or future claims that relate
            in any way to these Terms, your use of the Services, and/or rights
            of privacy and/or publicity. However, to the extent you have in any
            manner violated or threatened to violate our intellectual property
            rights, we may seek injunctive or other appropriate relief in any
            state or federal court in the State of Texas.
          </p>
          <p>
            You and RumbleOn each waive the right to a trial by jury or to
            participate in a class action. Any arbitration under this provision
            will take place on an individual basis — class arbitrations and
            class actions are not permitted. You also agree not to participate
            in claims against RumbleOn brought in a private attorney general or
            representative capacity, or consolidated claims involving another
            person’s account. However, either party may bring an individual
            action in small claims court. If, however, you or we transfer or
            appeal the small claim to a different court, we reserve our right to
            elect arbitration.
          </p>

          <p>
            Arbitration is more informal than a lawsuit in court. Arbitration
            uses a neutral arbitrator instead of a judge or jury, allows for
            more limited discovery, and is subject to limited review by courts.
            Arbitrators can award the same damages and relief that a court can
            award, including attorneys’ fees where permitted by law.
          </p>
          <p>
            A party seeking arbitration must first send to the other, by
            certified mail, a written notice of dispute (“Notice”). The Notice
            to RumbleOn should be addressed to RumbleOn, Inc., Attn: General
            Counsel, 901 W. Walnut Hill Lane, Suite 350-C Irving TX. 75038. The
            Notice must: (i) describe the nature and basis of the claim or
            dispute; and (ii) set forth the specific relief sought (“Demand”).
            If you and RumbleOn cannot resolve the Demand within 30 days after
            the Notice is received, you or RumbleOn may commence an arbitration
            proceeding. During the arbitration, the amount of any settlement
            offer made by RumbleOn or you shall not be disclosed to the
            arbitrator until after the arbitrator determines the amount, if any,
            that you or RumbleOn is entitled to.
          </p>
          <p>
            The arbitration shall be administered by (i) JAMS, pursuant to its
            Comprehensive Arbitration Rules and Procedures and in accordance
            with its expedited procedures contained in those rules, or (ii)
            American Arbitration Association in accordance with its Consumer
            Arbitration Rules. If you initiate the arbitration, you may choose
            one of the above administrators. If RumbleOn initiates arbitration,
            we will give you 20 days to choose the administrator. If you do not
            choose the administrator within that time frame, we will choose one.
            If the administrator a party chooses is unable or unwilling or
            ceases to serve as the administrator, then you or RumbleOn may
            choose the other administrator. If neither administrator is able or
            willing to serve as the administrator, the parties will mutually
            select an administrator, which must be a lawyer or retired judge
            with at least 15 years of legal experience.
          </p>
          <p>
            Any arbitration hearing that you attend must take place at a
            location reasonably convenient to your residence.
          </p>
          <p>
            You and RumbleOn will bear the administrator and arbitrator fees
            that each party is normally required to pay under the rules and law
            applicable to the proceeding. Each party will bear the expense of
            its own attorneys, experts, and witnesses, except where applicable
            law and/or the Agreement allows a party to recover attorney’s fees
            from the other party.
          </p>

          <p>
            The Federal Arbitration Act (“FAA”) governs this arbitration
            agreement. The arbitrator shall apply applicable substantive law
            consistent with the FAA and applicable statutes of limitations and
            claims of privilege. The arbitrator shall not apply federal or state
            rules of civil procedure or evidence. The arbitrator will not have
            authority to award damages, remedies, or awards that conflict with
            these Terms, unless otherwise provided by applicable law. Either
            party may make a timely request for a brief written explanation of
            the basis for the arbitration award. Judgment on the arbitrator’s
            award may be entered in any court with jurisdiction. Otherwise, the
            award and the arbitration proceeding shall be kept confidential. The
            arbitrator's decision is final and binding, except for any right of
            appeal provided by the FAA. Either party can appeal the award to a
            three-arbitrator panel administered by the administrator. The costs
            of such appeal will be borne by the appealing party, unless
            applicable law or the administrator’s rules provide otherwise.
          </p>
          <p>
            This arbitration provision survives the termination of your
            relationship with RumbleOn and/or your use of the Services. In the
            event of a conflict between this arbitration provision and the
            applicable arbitration rules or other provisions of the Terms, or
            any other agreement between us, this arbitration provision will
            govern any Dispute or Claim between you and RumbleOn, as defined in
            these Terms. If a court or arbitrator deems any part of this
            arbitration provision invalid or unenforceable under any law or
            statute consistent with the FAA, the remaining parts of this
            arbitration provision shall be enforceable despite such invalidity;
            however, if the prohibition of class relief and proceedings is found
            to be unenforceable or void in any proceeding, then this entire
            arbitration provision (except for this sentence) shall be null and
            void with respect to such proceeding, subject to the right to appeal
            such limitation or voiding.
          </p>
          <p>
            In order to reject this provision, RumbleOn must receive a signed
            writing (“Rejection Notice”) from you within 30 days of the date
            that you first accept these Terms (unless a longer period is
            required by applicable law). The Rejection Notice must include your
            (i) name, (ii) account number or username, (iii) mailing address,
            and (iv) a statement that you do not wish to resolve disputes with
            RumbleOn through arbitration, and must be mailed to RumbleOn, Inc.,
            Attn: General Counsel, 901 W. Walnut Hill Lane, Suite 350-C Irving
            TX. 75038 via certified mail, return receipt requested. Rejecting
            the arbitration provision will not affect any other aspect of these
            Terms, nor will it affect any other existing or future arbitration
            agreement between you and RumbleOn.
          </p>
          <h3>
            <u>20. Jurisdiction</u>
          </h3>

          <p>
            Any claim relating to these Terms, the Privacy Policy, any of the
            Services or Content will be governed by and interpreted in
            accordance with the laws of the State of Texas, U.S.A without
            reference to its conflict-of-laws principles and the United Nations
            Convention Relating to a Uniform Law on the International Sale of
            Goods may not be applied. Any dispute arising out of or related to
            any of the Services will be brought in, and you hereby consent to
            exclusive jurisdiction and venue in, the District Court of Dallas,
            Texas, U.S.A. You agree to waive all defenses of lack of personal
            jurisdiction and forum non-convenience and agree that process may be
            served in a manner authorized by applicable law or court rule.
            Notwithstanding the foregoing, we may seek injunctive relief in any
            court of competent jurisdiction.
          </p>

          <h3>
            <u>21. SMS Text Messages</u>
          </h3>

          <p>
            By opting in to text messages from RumbleOn, Inc. and its
            affiliates, agents and assigns (collectively “RumbleOn”) (the “Text
            Service”), you accept these Terms & Conditions and agree to resolve
            disputes with RumbleOn through binding arbitration and you waive any
            right to participate in class actions, all as detailed in the
            “Arbitration” section below. Opting In You acknowledge and agree
            that you opted in to the Text Service by providing your cellular
            number and thereby providing consent to receive such messages. By
            opting in: · You authorize RumbleOn to send text messages to the
            cell phone number associated with your Opt-in (i.e., the number from
            which you sent the Opt-in request, the number listed on the Opt-In
            form or instructions, the number for which you otherwise provided
            consent, or the number on file for the account associated with your
            Opt-In), including through the use of an automatic telephone dialing
            system. You also authorize RumbleOn to include marketing content in
            any such messages. You do not have to opt-in or agree to opt-in as a
            condition of any purchase. · You confirm that you are the subscriber
            to the relevant phone number or that you are the customary user of
            that number and that you are authorized to opt-in to receive these
            messages. · You consent to the use of an electronic record and
            electronic signature to document your opt-in. You are signing your
            Opt-In to the Text Service. · To withdraw that consent, or to update
            our records with your contact information, please call (214)
            617-3142. To view and retain an electronic copy of these Terms of
            Use or the rest of your Opt-In, you will need (i) a device (such as
            a computer or cell phone) with internet access, and (ii) either a
            printer or storage space on such device. For an email copy, you’ll
            also need an email account you can access from the device, along
            with a browser or other software that can display the emails. These
            Terms of Use still will apply if you withdraw the consent mentioned
            above or opt out of the Text Service. Frequency Unless otherwise
            noted, we may send multiple, recurring messages and/or terminate the
            Text Service or your participation in it at any time with or without
            notice, including, for example, before you have received any or all
            messages that you otherwise would have received. Cost Message and
            data rates may apply depending on your service plan, which may
            include fees from your carrier to send and/or receive text messages.
            If you click on a link within a text message, data charges may
            apply. How to Opt-Out Text STOP to stop receiving text messages. We
            may send you a final text message confirming that we received your
            request. How to Receive Help or Support For help regarding text
            messages, text HELP at any time. Privacy Policy You consent to the
            handling of your information as described in our Privacy Policy.
            Confidentiality Although we try to protect information you send us,
            we cannot guarantee that information sent using Short Message
            Service (SMS) is completely confidential. There are certain aspects
            of SMS usage and your device we cannot control. Therefore,
            transmission of information to you and to us over SMS must be
            undertaken at your own risk.
          </p>

          <h3>
            <u>22. Have Questions?</u>
          </h3>
          <p>
            If you have any questions (or comments) concerning these Terms or
            Privacy Policy, please send us an e-mail to the following address{" "}
            <a href="mailto:notices@rumbleon.com"> notices@rumbleon.com</a> and
            we will make an effort to reply within a reasonable timeframe.
          </p>

          <p>© 2021 RumbleOn, Inc., All Rights Reserved</p>
        </Col>
      </Row>
    </>
  );
};

export default TermsofUse;
